define('frontend2/components/drm/media-upload-store', ['exports', 'ember-validations', 'frontend2/components/drm/media-upload', 'frontend2/models/ext/enumerations'], function (exports, _emberValidations, _frontend2ComponentsDrmMediaUpload, _frontend2ModelsExtEnumerations) {
  var UPLOAD_NAME_MIN_CHARS;

  UPLOAD_NAME_MIN_CHARS = 2;

  exports['default'] = _frontend2ComponentsDrmMediaUpload['default'].extend(_emberValidations['default'], {
    init: function init() {
      return this._super();
    },
    _clearFields: function _clearFields() {
      return this.setProperties({
        // pos
        'upload.publisher': null,
        'upload.author': null,
        'upload.title': null,
        'upload.pos': null,
        'upload.start_week': moment().week(),
        'upload.duration': 1,
        'upload.issue_number': null,
        // common
        'upload.upload_common_area': null,
        'upload.upload_common_category': null,
        'upload.upload_common_commodity_group': null,
        'upload.date': null
      });
    },
    // clear fields that depend on the division if the divison changes
    _obDivisionChanged: Ember.observer('upload.division', function () {
      return this._clearFields();
    }),
    _obImageTypeChanged: Ember.observer('upload.upload_type', function () {
      return this._clearFields();
    }),
    isPress: Ember.computed.equal('upload.division', _frontend2ModelsExtEnumerations['default'].divisions.press),
    isBook: Ember.computed.equal('upload.division', _frontend2ModelsExtEnumerations['default'].divisions.book),
    validations: {
      /*
       * isPoSUpload
       */
      'upload.publisher': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.author': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isBook') && object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.title': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.pos': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.start_week': {
        presence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.duration': {
        presence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.issue_number': {
        presence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPress') && object.get('upload.isPoSUpload');
          }
        }
      },
      'upload.issue_number_year': {
        presence: {
          'if': function _if(object, validator) {
            return object.get('upload.isPress') && object.get('upload.isPoSUpload');
          }
        }
      },
      /*
       * isCommonUpload
       */
      'upload.upload_common_area': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isCommonUpload');
          }
        }
      },
      'upload.upload_common_category': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isCommonUpload');
          }
        }
      },
      'upload.upload_common_commodity_group': {
        modelPresence: {
          'if': function _if(object, validator) {
            return object.get('upload.isCommonUpload');
          }
        }
      },
      'upload.date': {
        presence: {
          'if': function _if(object, validator) {
            return object.get('upload.isCommonUpload');
          }
        }
      }
    }
  });
});