define('frontend2/initializers/inject-i18n', ['exports'], function (exports) {
  var InjectI18nInitializer;

  InjectI18nInitializer = {
    name: 'InjectI18n',
    initialize: function initialize(application) {
      application.inject('controller', 'i18n', 'service:i18n');
      application.inject('component', 'i18n', 'service:i18n');
      return application.inject('route', 'i18n', 'service:i18n');
    }
  };

  exports['default'] = InjectI18nInitializer;
});