define('frontend2/components/ext/select-after', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    store: Ember.inject.service(),
    shouldShow: Ember.computed('select.searchText', 'select.results.[]', 'select.results.@each.name', function () {
      var doesItExist, select;
      select = this.get('select');
      if (select.searchText.trim() === '') {
        return false;
      }
      doesItExist = select.results.findBy('name', select.searchText.trim());
      if (doesItExist) {
        return false;
      }
      return true;
    }),
    actions: {
      createRecord: function createRecord() {
        var record;
        return regeneratorRuntime.async(function createRecord$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('store').createRecord(this.get('extra.modelName'), {
                name: this.get('select.searchText')
              }).save());

            case 2:
              record = context$1$0.sent;
              return context$1$0.abrupt('return', this.get('select.actions').choose(record));

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    }
  });
});