define('frontend2/routes/administration/users/index', ['exports'], function (exports) {
  var AdministrationUsersIndexRoute;

  AdministrationUsersIndexRoute = Ember.Route.extend({
    user: Ember.inject.service(),
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('user.model').permitted('canManageUsers'));

          case 2:
            if (context$1$0.sent) {
              context$1$0.next = 4;
              break;
            }

            return context$1$0.abrupt('return', this.transitionTo('/'));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    model: function model() {
      return this.get('store').findAll('user');
    },
    actions: {
      willTransition: function willTransition(transition) {
        return regeneratorRuntime.async(function willTransition$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this.get('authorization').load());

            case 2:
              return context$1$0.abrupt('return', context$1$0.sent);

            case 3:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      createUser: function createUser() {
        this.controller.incrementProperty('reload');
        return true;
      }
    }
  });

  exports['default'] = AdministrationUsersIndexRoute;
});

// reload permissions