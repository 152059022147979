define('frontend2/routes/media', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  ;
  var MediaRoute;

  MediaRoute = Ember.Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    user: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var transitionCompany, userCompany;
      if (!this.get('user.is_admin')) {
        userCompany = this.get('user.data.company_slug');
        transitionCompany = transition.params.media.company_slug;
        if (userCompany !== transitionCompany) {
          return this.transitionTo('index');
        }
      }
      return transition;
    },
    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.get('store').findAll('user').then(function (users) {
          var user;
          user = users.findBy('company_slug', params.company_slug);
          return resolve(user);
        }, function (error) {
          return reject(error);
        });
      });
    }
  });

  exports['default'] = MediaRoute;
});