define('frontend2/components/ext/contact-form', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  exports['default'] = Ember.Component.extend(_emberValidations['default'], {
    // TODO does not work, don't know why
    validations: {
      'model.first_name': {
        presence: {
          message: 'Name muss vorhanden sein'
        },
        length: {
          minimum: 2,
          messages: {
            tooShort: 'Mindestens zwei Zeichen werden benötigt.'
          }
        }
      }
    }
  });
});