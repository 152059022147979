define('frontend2/routes/users/password-reset', ['exports'], function (exports) {
  var PasswordResetRoute;

  PasswordResetRoute = Ember.Route.extend({
    model: function model() {
      return Ember.Object.create({
        email: ''
      });
    }
  });

  exports['default'] = PasswordResetRoute;
});