define('frontend2/authorizers/local', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _emberSimpleAuthAuthorizersBase) {
  ;
  var LocalAuthorizer;

  LocalAuthorizer = _emberSimpleAuthAuthorizersBase['default'].extend();

  // authorize: (jqXHR, requestOptions) ->
  //   session = @get('session')
  //   user = session.content.user

  //   if session.isAuthenticated and not Ember.isEmpty(user.id)
  //     jqXHR.setRequestHeader 'Authorization', user.id;
  //  return
  exports['default'] = LocalAuthorizer;
  ;
});