define('frontend2/controllers/book/page', ['exports'], function (exports) {
  var BookPageController;

  BookPageController = Ember.Controller.extend({
    //#
    // PROPERTIES, BINDINGS, ETC
    //#
    book: Ember.computed.alias('model.book'),
    page: Ember.computed.alias('model'),
    is_dirty: Ember.computed('', 'book.sortChangeProperty', 'book.hasDirtyAttributes', 'page.hasDirtyAttributes', 'book.pages.[]', 'book.pages.@each.idx', 'book.pages.@each.hasDirtyAttributes', 'book.layout.hasDirtyAttributes', function () {
      // WORKAROUND: without this call the property listeners do not get setup and this is never recalculated
      this.get('page.hasDirtyAttributes');
      // check for dirtyAttributes on the pages and on the book layout
      if (this.get('book.pages')) {
        return this.get('book.pages').filterBy('hasDirtyAttributes').length > 0 || this.get('book.layout.hasDirtyAttributes') || this.get('book.hasDirtyAttributes');
      } else {
        return false;
      }
    })
  });

  exports['default'] = BookPageController;
  ;
});