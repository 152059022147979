define('frontend2/models/user', ['exports', 'ember-data', 'ember-validations'], function (exports, _emberData, _emberValidations) {
  ;
  ;
  var User;

  User = _emberData['default'].Model.extend(_emberValidations['default'], {
    company: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    company_slug: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    display_name: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    email: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),
    passwordConfirmation: _emberData['default'].attr('string'), // has to be camelcase: https://github.com/dockyard/ember-validations#confirmation
    role: _emberData['default'].attr('string', {
      defaultValue: 'customer'
    }),
    active: _emberData['default'].attr('boolean', {
      defaultValue: true
    }),
    verified: _emberData['default'].attr('boolean', {
      defaultValue: false
    }),
    bucket_users: _emberData['default'].hasMany('bucket-user', {
      async: true
    }),
    buckets_size: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    plan: _emberData['default'].belongsTo('plan', {
      async: true
    }),
    customer: _emberData['default'].belongsTo('customer', {
      async: true
    }),
    organization: _emberData['default'].belongsTo('organization', {
      async: true
    }),
    user_roles: _emberData['default'].hasMany('user-role', {
      async: true
    }),
    bucket_user_roles: _emberData['default'].hasMany('bucket-user-role', {
      async: true
    }),
    standard_bucket: _emberData['default'].belongsTo('bucket', {
      async: true,
      inverse: null
    })
  });

  User.reopen({
    i18n: Ember.inject.service(),
    authorization: Ember.inject.service(),
    save: function save() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      this.set('company_slug', this.get('company').parameterize());
      return this._super(options);
    },
    role_as_string: (function () {
      var roles;
      // role = @get 'role'
      // return @get('i18n').t("user_role.#{role}")

      roles = this.get('roles.content');
      console.log(roles);
      if (roles) {
        return roles.mapBy('name').join(", ");
      }
      return "";
    }).property('roles'),
    full_name: (function () {
      return this.get('first_name') + ' ' + this.get('last_name');
    }).property('first_name', 'last_name'),
    // check if a user has a certain permission
    // @params (String) permission
    permitted: function permitted(permission) {
      return this.get('authorization').getGlobalPermissions().indexOf(permission) >= 0;
    },
    // check if a user has all permissions
    // @params (Array<String>) permissions
    permittedAll: function permittedAll(permissions) {
      var allowed, i, len, permission;
      allowed = true;
      for (i = 0, len = permissions.length; i < len; i++) {
        permission = permissions[i];
        if (!this.permitted(permission)) {
          allowed = false;
          break;
        }
      }
      return allowed;
    },
    // check if a user has at least one of the given permissions
    // @params (Array<String>) permissions
    permittedAny: function permittedAny(permissions) {
      var allowed, i, len, permission;
      allowed = false;
      for (i = 0, len = permissions.length; i < len; i++) {
        permission = permissions[i];
        if (this.permitted(permission)) {
          allowed = true;
          break;
        }
      }
      return allowed;
    },
    set_active: function set_active() {
      var active = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, {
        active: active
      }, 'activate');
    },
    update_profile: function update_profile(data_for_update) {
      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, data_for_update, 'update-profile');
    },
    change_password: function change_password(passwords) {
      var adapter;
      adapter = this.get('store').adapterFor(this.constructor.modelName);
      return adapter.custom_rest(this, passwords, 'change-password');
    },
    short: Ember.computed('company', function () {
      var company, parts;
      company = this.get('company');
      if (company.indexOf(" ") > 0) {
        parts = company.split(" ");
        return ('' + parts[0].substring(0, 1) + parts[1].substring(0, 1)).toUpperCase();
      } else {
        return company.substring(0, 2).toUpperCase();
      }
    }),
    quotaInPercent: Ember.computed('buckets_size', 'plan.size', function () {
      return this.get('buckets_size') / this.get('plan.size');
    }),
    isQuotaReached: function isQuotaReached(file) {
      return this.get('buckets_size') + file.filesize > this.get('plan.size');
    },
    addRole: function addRole(role) {
      var existingUserRole, userRole;
      // check if the role exists and is deleted
      existingUserRole = this.get('store').peekAll('user-role').find(function (item) {
        return !item.get('user.id') && item.get('role.id') === role.id;
      });
      if (existingUserRole) {
        userRole = existingUserRole.rollbackAttributes();
        return this.get('user_roles').pushObject(existingUserRole);
      } else {
        userRole = this.get('store').createRecord('user-role', {
          user: this,
          role: role
        });
        return this.get('user_roles').pushObject(userRole);
      }
    },
    removeRole: function removeRole(role) {
      var userRole, userRoles;
      return regeneratorRuntime.async(function removeRole$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('user_roles'));

          case 2:
            userRoles = context$1$0.sent;

            userRole = userRoles.find(function (item) {
              return item.get('user.id') === _this.get('id') && item.get('role.id') === role.id;
            });
            this.get('user_roles').removeObject(userRole);

            if (!userRole.get('isNew')) {
              context$1$0.next = 9;
              break;
            }

            return context$1$0.abrupt('return', userRole.destroyRecord());

          case 9:
            return context$1$0.abrupt('return', userRole.deleteRecord());

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    toString: function toString() {
      return this.get('display_name') + ' (<small>' + this.get('email') + '</small>)';
    }
  });

  exports['default'] = User;
  ;
});