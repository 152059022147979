define('frontend2/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
  var Role;

  Role = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    role_permissions: _emberData['default'].hasMany('role-permission', {
      async: true
    }),
    permissions: Ember.computed('role_permissions.[]', function callee$0$0() {
      var promises, rolePermissions;
      return regeneratorRuntime.async(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            promises = [];
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('role_permissions'));

          case 3:
            rolePermissions = context$1$0.sent;

            rolePermissions.forEach(function (rolePermission) {
              return promises.push(rolePermission.get('permission'));
            });
            context$1$0.t0 = this;
            context$1$0.next = 8;
            return regeneratorRuntime.awrap(Promise.all(promises));

          case 8:
            context$1$0.t1 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t0.set.call(context$1$0.t0, 'permissions', context$1$0.t1));

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    })
  });

  exports['default'] = Role;
});