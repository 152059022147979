define('frontend2/components/folder-view', ['exports', 'ember'], function (exports, _ember) {
  var FolderViewComponent;

  FolderViewComponent = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    classNames: ['folder-view'],
    loading: true,
    buckets: [],
    init: function init() {
      var _this = this;

      this._super();
      return this.get('store').findAll('bucket').then(function (buckets) {
        _this.set('buckets', buckets);
        return _this.set('loading', false);
      });
    }
  });

  exports['default'] = FolderViewComponent;
});