define('frontend2/models/upload', ['exports', 'ember-data', 'frontend2/config/environment', 'frontend2/models/ext/upload-mixin'], function (exports, _emberData, _frontend2ConfigEnvironment, _frontend2ModelsExtUploadMixin) {
  var Upload;

  Upload = _emberData['default'].Model.extend(_frontend2ModelsExtUploadMixin['default'], {
    original_file_name: _emberData['default'].attr('string'),
    path: _emberData['default'].attr('string'),
    url: _emberData['default'].attr('string'),
    content_type: _emberData['default'].attr('string'),
    bucket: _emberData['default'].belongsTo('bucket'),
    name: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    source: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    license: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    expiry_date: _emberData['default'].attr('date'),
    reference_number: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    download_link: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    tag_uploads: _emberData['default'].hasMany('tag-upload', {
      async: true
    }),
    is_temporary: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    temporary_id: _emberData['default'].attr('number', {
      defaultValue: -1
    }),
    description: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    published: _emberData['default'].attr('boolean', {
      defaultValue: false
    }),
    width: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    height: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    format: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    depth: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    color: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    res: _emberData['default'].attr('string', {
      defaultValue: ''
    }),
    filesize: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    filesize_total: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    hasTemporaryFile: false,
    tempId: -1,
    temporaryUploadModelJson: null,
    getInfosAutomatically: false,
    selected: false,
    thumbnail: function thumbnail(name) {
      var loadReplaced = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var id, path;
      id = loadReplaced ? this.get('tempId') : this.get('id');
      path = '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/uploads/' + id + '/view/' + (name || 'original');
      // hack when file is replaced, before the xs thumbnail path would not change
      if (this.get('updated_at')) {
        path += '?' + this.get('updated_at').getTime();
      }
      // path += "?#{Math.floor Math.random() * 10000}" if @get('_wasReplaced')
      return path;
    },
    tagsAsCsv: Ember.computed('tag_uploads.[]', function () {
      var names;
      names = this.get('tag_uploads').mapBy('tag.name');
      return names.join(", ");
    }),
    isExpired: Ember.computed('expiry_date', function () {
      var expiryDate, now;
      now = moment();
      expiryDate = moment(this.get('expiry_date'));
      return now.isAfter(expiryDate);
    })
  });

  Upload.reopen({
    selectedTags: Ember.computed('tag_uploads.[]', function () {
      var _this = this;

      var selected;
      selected = [];
      return this.get('tag_uploads').then(function (tagUploads) {
        tagUploads.forEach(function (tagUpload) {
          return selected.pushObject(tagUpload.get('tag'));
        });
        return _this.set('selectedTags', selected);
      });
    })
  });

  exports['default'] = Upload;
});