define('frontend2/routes/administration/users/index/roles', ['exports'], function (exports) {
  exports['default'] = Ember.Route.extend({
    overlay: Ember.inject.service(),
    model: function model(params) {
      return new Ember.RSVP.hash({
        roles: this.store.query('role', {
          sort: 'name'
        }),
        user: this.store.find('user', params.user_id),
        user_roles: this.store.query('user-role', {
          user_id: params.user_id
        }),
        bucket_user_roles: this.store.query('bucket-user-role', {
          user_id: params.user_id
        }),
        buckets: this.store.findAll('bucket')
      });
    },
    afterModel: function afterModel(model) {
      var promises;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            promises = [];
            model.user_roles.forEach(function (userRole) {
              return promises.push(userRole.get('role'));
            });
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(Promise.all(promises));

          case 4:
            return context$1$0.abrupt('return', model.assignedRoles = context$1$0.sent);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    setupController: function setupController(controller, modelData) {
      return controller.setProperties(modelData);
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('administration.users.index');
      });
    },
    deactivate: function deactivate() {
      var _this2 = this;

      var bucketRoleAssignments, userRoleAssignments;
      this.get('overlay').hide();
      userRoleAssignments = this.get('store').peekAll('user-role');
      // discard user role changes
      userRoleAssignments.forEach(function (userRoleAssignment) {
        if (userRoleAssignment.get('isNew') && userRoleAssignment.get('currentState.stateName') !== 'root.loaded.created.inFlight') {
          return userRoleAssignment.deleteRecord();
        } else if (userRoleAssignment.get('isDeleted')) {
          userRoleAssignment.rollbackAttributes();
          return _this2.currentModel.user.get('user_roles').pushObject(userRoleAssignment);
        }
      });
      // discard bucket role changes
      bucketRoleAssignments = this.get('store').peekAll('bucket-user-role');
      return bucketRoleAssignments.forEach(function (bucketRoleAssignment) {
        if (bucketRoleAssignment.get('isNew')) {
          return bucketRoleAssignment.deleteRecord();
        } else if (bucketRoleAssignment.get('isDeleted')) {
          return bucketRoleAssignment.rollbackAttributes();
        }
      });
    },
    actions: {
      toggleBucketRole: function toggleBucketRole(user, bucket, role, event) {
        event.preventDefault();
        event.stopPropagation();
        if ($(event.target).is(':checked')) {
          bucket.addRole(user, role);
        } else {
          bucket.removeRole(user, role);
        }
        return false;
      },
      saveRoles: function saveRoles(user) {
        var promises;
        return regeneratorRuntime.async(function saveRoles$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(this._saveUserRoles(user));

            case 2:
              promises = [];
              this.get('store').peekAll('bucket-user-role').filterBy('hasDirtyAttributes').forEach(function (bucketRoleAssignment) {
                return promises.push(bucketRoleAssignment.save());
              });
              context$1$0.next = 6;
              return regeneratorRuntime.awrap(Promise.all(promises));

            case 6:
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(user.save());

            case 8:
              return context$1$0.abrupt('return', this.transitionTo('administration.users.index'));

            case 9:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      }
    },
    _saveUserRoles: function _saveUserRoles(user) {
      var _this3 = this;

      this.currentModel.user_roles.forEach(function callee$1$0(userRole) {
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              if (this.currentModel.assignedRoles.findBy('id', userRole.get('role.id'))) {
                context$2$0.next = 4;
                break;
              }

              context$2$0.next = 3;
              return regeneratorRuntime.awrap(userRole.destroyRecord());

            case 3:
              return context$2$0.abrupt('return', context$2$0.sent);

            case 4:
            case 'end':
              return context$2$0.stop();
          }
        }, null, _this3);
      });
      return this.controller.get('assignedRoles').forEach(function callee$1$0(assignedRole) {
        var userRoleRecord;
        return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
          while (1) switch (context$2$0.prev = context$2$0.next) {
            case 0:
              if (this.currentModel.user_roles.findBy('role.id', assignedRole.id)) {
                context$2$0.next = 5;
                break;
              }

              userRoleRecord = this.get('store').createRecord('user-role', {
                role: assignedRole,
                user: user
              });
              context$2$0.next = 4;
              return regeneratorRuntime.awrap(userRoleRecord.save());

            case 4:
              return context$2$0.abrupt('return', context$2$0.sent);

            case 5:
            case 'end':
              return context$2$0.stop();
          }
        }, null, _this3);
      });
    }
  });
});