define('frontend2/models/bucket-user', ['exports', 'ember-data'], function (exports, _emberData) {
  var BucketUser;

  BucketUser = _emberData['default'].Model.extend({
    bucket: _emberData['default'].belongsTo('bucket', {
      async: true
    }),
    user: _emberData['default'].belongsTo('user', {
      async: true
    })
  });

  exports['default'] = BucketUser;
});