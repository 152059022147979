define('frontend2/routes/user/profile', ['exports'], function (exports) {
  var UserProfileRoute;

  UserProfileRoute = Ember.Route.extend({
    user: Ember.inject.service(),
    model: function model() {
      return new Ember.RSVP.hash({
        model: this.store.findRecord('user', this.get('user.data.id')),
        plans: this.store.query('plan', {
          sort: "idx ASC"
        })
      });
    },
    setupController: function setupController(controller, hash) {
      return controller.setProperties(hash);
    }
  });

  exports['default'] = UserProfileRoute;
});