define('frontend2/routes/buckets/show/upload', ['exports', 'frontend2/routes/buckets/search-show-upload-mixin'], function (exports, _frontend2RoutesBucketsSearchShowUploadMixin) {
  exports['default'] = Ember.Route.extend(_frontend2RoutesBucketsSearchShowUploadMixin['default'], {
    onBack: function onBack() {
      return this.transitionTo('buckets.show', this.modelFor('buckets.show'));
    },
    actions: {
      share: function share(upload) {
        return this.transitionTo('buckets.show.share', upload.get('id'));
      },
      shareWithPublisher: function shareWithPublisher(upload) {
        return this.transitionTo('buckets.show.share-with-publisher', upload.get('id'));
      },
      giveFeedback: function giveFeedback(upload) {
        return this.transitionTo('buckets.show.give-feedback', upload.get('id'));
      }
    }
  });
});