define('frontend2/instance-initializers/patch-ember-power-select', ['exports'], function (exports) {
  var Initializer, initialize;

  exports.initialize = initialize = function (app) {
    var powerSelect;
    powerSelect = app.resolveRegistration('component:power-select');
    return powerSelect.reopen({
      loadingMessage: 'Lädt...',
      noMatchesMessage: 'Keine Ergebnisse',
      searchMessage: 'Tippen um zu suchen'
    });
  };

  Initializer = {
    name: 'patch-ember-power-select',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports['default'] = Initializer;
});