define('frontend2/models/bucket', ['exports', 'ember-data', 'frontend2/models/ext/bucket-mixin'], function (exports, _emberData, _frontend2ModelsExtBucketMixin) {
  var Bucket;

  Bucket = _emberData['default'].Model.extend(_frontend2ModelsExtBucketMixin['default'], {
    user: Ember.inject.service(),
    authorization: Ember.inject.service(),
    name: _emberData['default'].attr('string'),
    uploads: _emberData['default'].hasMany('upload', {
      async: true
    }),
    owner: _emberData['default'].belongsTo('user', {
      async: true
    }),
    bucket_users: _emberData['default'].hasMany('bucket-user', {
      async: true
    }),
    size: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    is_for_organization: _emberData['default'].attr('boolean', {
      defaultValue: true
    }),
    bucket_user_roles: _emberData['default'].hasMany('bucket-user-role', {
      async: true
    }),
    count: _emberData['default'].attr('number'),
    currentUserIsOwner: Ember.computed('user.data', 'owner', function () {
      var ref, ref1;
      return ((ref = this.get('owner.id')) != null ? ref.toString() : void 0) === ((ref1 = this.get('user.data.id')) != null ? ref1.toString() : void 0);
    }),
    // check if a user has a certain permission
    // @params (String) permission
    permitted: function permitted(permission) {
      var permissions;
      permissions = this.get('authorization').getBucketPermissions(this.get('id'));
      return permissions.indexOf(permission) >= 0;
    },
    // check if a user has all permissions
    // @params (Array<String>) permissions
    permittedAll: function permittedAll(permissions) {
      var allowed, i, len, permission;
      allowed = true;
      for (i = 0, len = permissions.length; i < len; i++) {
        permission = permissions[i];
        if (!this.permitted(permission)) {
          allowed = false;
          break;
        }
      }
      return allowed;
    },
    // check if a user has at least one of the given permissions
    // @params (Array<String>) permissions
    permittedAny: function permittedAny(permissions) {
      var allowed, i, len, permission;
      allowed = false;
      for (i = 0, len = permissions.length; i < len; i++) {
        permission = permissions[i];
        if (this.permitted(permission)) {
          allowed = true;
          break;
        }
      }
      return allowed;
    },
    addRole: function addRole(user, role) {
      var existingRoleAssignment, newRoleAssignment;
      existingRoleAssignment = this.get('store').peekAll('bucket-user-role').find(function (item) {
        return item.get('user.id') === user.id && item.get('role.id') === role.id && !item.get('bucket.id');
      });
      if (existingRoleAssignment) {
        existingRoleAssignment.rollbackAttributes();
        return this.get('bucket_user_roles').pushObject(existingRoleAssignment);
      } else {
        newRoleAssignment = this.get('store').createRecord('bucket-user-role', {
          user: user,
          bucket: this,
          role: role
        });
        return this.get('bucket_user_roles').pushObject(newRoleAssignment);
      }
    },
    removeRole: function removeRole(user, role) {
      var assignedRoles, roleToRemove;
      return regeneratorRuntime.async(function removeRole$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('bucket_user_roles'));

          case 2:
            assignedRoles = context$1$0.sent;

            roleToRemove = assignedRoles.find(function (item) {
              return item.get('user.id') === user.id && item.get('role.id') === role.id && item.get('bucket.id') === _this.id;
            });
            roleToRemove._oldBucket = roleToRemove.get('bucket');
            this.get('bucket_user_roles').removeObject(roleToRemove);

            if (!roleToRemove.get('isNew')) {
              context$1$0.next = 10;
              break;
            }

            return context$1$0.abrupt('return', roleToRemove.destroyRecord());

          case 10:
            return context$1$0.abrupt('return', roleToRemove.deleteRecord());

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    toString: function toString() {
      return this.get('name');
    }
  });

  exports['default'] = Bucket;
});