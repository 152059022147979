define('frontend2/authenticators/base', ['exports', 'ember-simple-auth/authenticators/base', 'frontend2/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsBase, _frontend2ConfigEnvironment) {
  ;

  var BaseAuthenticator;

  BaseAuthenticator = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    host: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/',
    restore: function restore(properties) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(properties)) {
          return Ember.$.ajax({
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            url: _this.host + 'api/users/' + properties.user.id,
            type: 'GET',
            contentType: 'application/json'
          }).then(function (response) {
            return resolve(properties);
          }, function (xhr, status, error) {
            var response_text;
            response_text = JSON.parse(xhr.responseText);
            return reject(response_text.error);
          });
        } else {
          return reject();
        }
      });
    },
    invalidate: function invalidate(properties) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: _this2.host + 'logout',
          type: 'POST'
        }).always(function () {
          resolve();
        });
      });
    }
  });

  exports['default'] = BaseAuthenticator;
  ;
});