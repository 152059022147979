define('frontend2/components/administration/users/roles/role-assignment', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    single: true,
    colsPerRole: 2,
    init: function init() {
      return regeneratorRuntime.async(function init$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super();
            context$1$0.t0 = this;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.get('store').findAll('role'));

          case 4:
            context$1$0.t1 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t0.set.call(context$1$0.t0, 'roles', context$1$0.t1));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    actions: {
      toggleRole: function toggleRole(role, e) {
        var assignedRoles;
        if (this.get('changed')) {
          return this.get('changed')(role, e);
        } else if (this.get('single')) {
          this.get('assignedRoles').clear();
          return this.get('assignedRoles').pushObject(role);
        } else {
          assignedRoles = this.get('assignedRoles');
          if (assignedRoles.includes(role)) {
            return assignedRoles.removeObject(role);
          } else {
            return assignedRoles.pushObject(role);
          }
        }
      }
    }
  });

  // fire action "changed" if set
});