define('frontend2/helpers/is-fulfilled', ['exports', 'ember-promise-helpers/helpers/is-fulfilled'], function (exports, _emberPromiseHelpersHelpersIsFulfilled) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsFulfilled['default'];
    }
  });
  Object.defineProperty(exports, 'isFulfilled', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsFulfilled.isFulfilled;
    }
  });
});