define('frontend2/components/folder-view-item', ['exports', 'ember'], function (exports, _ember) {
  var FolderViewItemComponent;

  FolderViewItemComponent = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    tagName: 'li',
    classNameBindings: ['active'],
    active: false,
    bucket: null,
    dragOver: function dragOver() {
      return this.set('active', true);
    },
    dragLeave: function dragLeave() {
      return this.set('active', false);
    },
    drop: function drop(e) {
      var ok, upload, uploadId;
      uploadId = e.dataTransfer.getData('uploadId');
      upload = this.get('store').peekRecord('upload', uploadId);
      ok = confirm(this.get('i18n').t('upload.confirmations.move').toString());
      if (ok) {
        upload.set('bucket', this.get('bucket'));
        return upload.save();
      }
    }
  });

  exports['default'] = FolderViewItemComponent;
});