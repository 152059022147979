define('frontend2/routes/user/profile/edit-profile', ['exports'], function (exports) {
  var EditProfileRoute;

  EditProfileRoute = Ember.Route.extend({
    overlay: Ember.inject.service(),
    model: function model() {
      var userProfileModels;
      userProfileModels = this.modelFor('user.profile');
      return userProfileModels.model;
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('user.profile');
      });
    },
    deactivate: function deactivate() {
      return this.get('overlay').hide();
    }
  });

  exports['default'] = EditProfileRoute;
});