define('frontend2/controllers/users/password-reset', ['exports', 'ember', 'ember-validations', 'frontend2/validators/patterns', 'frontend2/config/environment'], function (exports, _ember, _emberValidations, _frontend2ValidatorsPatterns, _frontend2ConfigEnvironment) {
  ;
  ;
  ;

  var UsersLoginController;

  UsersLoginController = _ember['default'].Controller.extend(_emberValidations['default'], {
    i18n: _ember['default'].inject.service(),
    is_not_valid: _ember['default'].computed.not('isValid'),
    notify: _ember['default'].inject.service(),
    isProcessing: false,
    // validation / i18n bug: https://github.com/dockyard/ember-validations/issues/224
    set_validation_messages: (function () {
      this.get('validations')['model.email']['presence']['message'] = this.get('i18n').t("validation_messages.email.required").toString();
      return this.get('validations')['model.email']['format']['message'] = this.get('i18n').t("validation_messages.email.invalid_format").toString();
    }).on('init'),
    validations: {
      'model.email': {
        presence: {
          message: ''
        },
        format: {
          'with': _frontend2ValidatorsPatterns['default'].email,
          message: ''
        }
      }
    },
    actions: {
      requestNewPassword: function requestNewPassword() {
        var _this = this;

        this.set('isProcessing', true);
        return _ember['default'].$.ajax({
          crossDomain: true,
          data: {
            email: this.get('model.email')
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/users/reset_password',
          type: 'POST',
          success: function success(r) {
            _this.set('isProcessing', false);
            _this.get('notify').success(_this.get('i18n').t('password-reset.success-check-emails').toString());
            return _this.transitionToRoute('users.login');
          },
          error: function error(r) {
            _this.set('isProcessing', false);
            return _this.get('notify').error(_this.get('i18n').t('password-reset.email-does-not-exist').toString());
          }
        });
      }
    }
  });

  exports['default'] = UsersLoginController;
  ;
});