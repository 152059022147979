define('frontend2/routes/administration/mixins/create-route-factory', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  exports['default'] = function (modelName) {
    return Ember.Mixin.create({
      templateName: 'administration/maintenance-view-create',
      overlay: Ember.inject.service(),
      model: function model() {
        var _this = this;

        var genericModel;
        genericModel = this.get('store').createRecord(modelName);
        // initialize belongsTo like contact of publisher
        genericModel.eachRelationship(function (name, descriptor) {
          if (descriptor.kind === 'belongsTo') {
            return genericModel.set(name, _this.get('store').createRecord(descriptor.type));
          }
        });
        return genericModel;
      },
      setupController: function setupController(controller, model) {
        return controller.setProperties({
          model: model,
          modelName: modelName
        });
      },
      activate: function activate() {
        var _this2 = this;

        return this.get('overlay').show(function () {
          _this2.transitionTo('administration.' + (0, _emberInflector.pluralize)(modelName) + '.index');
          if (_this2.modelFor(_this2.routeName).get('isNew')) {
            _this2.modelFor(_this2.routeName).deleteRecord();
          }
          return _this2.get('overlay').hide();
        });
      },
      actions: {
        submit: function submit() {
          return this.send('create', this.currentModel);
        }
      }
    });
  };

  ;
});