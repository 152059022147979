define('frontend2/routes/buckets/search-show-mixin', ['exports', 'lodash', 'frontend2/config/environment', 'ember-keyboard'], function (exports, _lodash, _frontend2ConfigEnvironment, _emberKeyboard) {
  exports['default'] = Ember.Mixin.create(_emberKeyboard.EKMixin, {
    onCmdA: Ember.on((0, _emberKeyboard.keyDown)('cmd+KeyA'), function (e) {
      var filteredUploads;
      e.preventDefault();
      e.stopPropagation();
      filteredUploads = this.controllerFor(this.routeName).get('filteredUploads');
      if (filteredUploads.filterBy('selected', false).get('length') === 0) {
        filteredUploads.setEach('selected', false);
      } else {
        filteredUploads.setEach('selected', true);
      }
      return false;
    }),
    _getSelectedUploadIds: function _getSelectedUploadIds() {
      var selectedUploadIds, yesOrNo;
      selectedUploadIds = _lodash['default'].map(this.controller.get('filteredUploads').filterBy('selected', true), function (item) {
        return item.get('id');
      });
      if (selectedUploadIds.length === 0) {
        yesOrNo = confirm('Ganzen Ordner teilen? (mit STRG+Klick einzelne Bilder auswählen)');
        if (!yesOrNo) {
          return false;
        }
        // all uploads are shared
        selectedUploadIds = _lodash['default'].map(this.controller.get('filteredUploads'), function (item) {
          return item.get('id');
        });
      }
      return selectedUploadIds;
    },
    actions: {
      willTransition: function willTransition() {
        this._super();
        return this.set('keyboardActivated', false);
      },
      didTransition: function didTransition() {
        this._super();
        return this.set('keyboardActivated', true);
      },
      publish: function publish(close) {
        var _this = this;

        var selectedUploadIds;
        selectedUploadIds = this._getSelectedUploadIds();
        // make a call to the server
        Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/uploads/publish',
          type: 'POST',
          data: {
            uploads: selectedUploadIds
          }
        }).then(function (response) {
          _this.get('store').pushPayload(response);
          return _this.get('notify').success('Bilder wurden veröffentlicht');
        }, function (xhr, status, error) {
          return this.get('notify').error('Fehler 64b10db1, bitte den Support kontaktieren.');
        });
        close();
        return false;
      },
      shared: function shared(routeModel) {
        var _this2 = this;

        var ccEmail, comment, selectedUploadIds, toEmail;
        toEmail = routeModel.get('email');
        ccEmail = routeModel.get('email_cc');
        comment = routeModel.get('comment');
        if ('selected' === routeModel.get('uploadId')) {
          selectedUploadIds = this._getSelectedUploadIds();
        } else {
          selectedUploadIds = [routeModel.get('uploadId')];
        }
        // make a call to the server
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/share/',
          type: 'POST',
          data: {
            email: toEmail,
            cc: ccEmail,
            comment: comment,
            uploads: selectedUploadIds,
            shareWithPublisher: routeModel.get('shareWithPublisher')
          }
        }).then(function (response) {
          // change shared state for this model (only store bucket type shares)
          console.log(response.url);
          return _this2.get('notify').success('Sie haben Dateien mit ' + toEmail + ' geteilt.');
        }, function (xhr, status, error) {
          return this.get('notify').error('Fehler 8d4e1b97, bitte kontaktieren Sie den Support.');
        });
      },
      shareWithPublisher: function shareWithPublisher(routeModel) {
        var uploadIds;
        uploadIds = routeModel.get('uploads').map(function (upload) {
          return upload.get('id');
        });
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/share-with-publisher/',
          type: 'POST',
          data: {
            ccReceivers: routeModel.get('ccReceivers'),
            publisher_id: routeModel.get('publisher.id'),
            upload_id: routeModel.get('uploadSample.id'),
            upload_ids: uploadIds,
            comment: routeModel.get('comment')
          }
        }).then(function (response) {
          routeModel.get('uploads').setEach('shared', true);
          console.log(response);
          return this.get('notify').success('Bilder erfolgreich an den Verlag gesendet.');
        }, function (xhr, status, error) {
          return console.log(status, error);
        });
      },
      unpublish: function unpublish(close) {
        var _this3 = this;

        var selectedUploadIds;
        selectedUploadIds = this._getSelectedUploadIds();
        // make a call to the server
        Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/uploads/unpublish',
          type: 'POST',
          data: {
            uploads: selectedUploadIds
          }
        }).then(function (response) {
          _this3.get('store').pushPayload(response);
          return _this3.get('notify').success('Veröffentlichung der Bilder  zurückgenommen.');
        }, function (xhr, status, error) {
          return this.get('notify').error('Fehler 4d21d33d, bitte kontaktieren Sie den Support.');
        });
        close();
        return false;
      },
      giveFeedback: function giveFeedback(routeModel) {
        var selectedUploadIds;
        if ('selected' === routeModel.get('uploadId')) {
          selectedUploadIds = this._getSelectedUploadIds();
        } else {
          selectedUploadIds = [routeModel.get('uploadId')];
        }
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/uploads/give-feedback',
          type: 'POST',
          data: {
            feedbackText: routeModel.feedback,
            uploads: selectedUploadIds
          }
        }).then(function (response) {
          console.log(response);
          return this.get('notify').success('Feedback erfolgreich gesendet');
        }, function (xhr, status, error) {
          this.get('notify').success('Fehler 8a7bbf35, bitte kontaktieren Sie den Support.');
          return console.log(status, error);
        });
      }
    }
  });
});