define('frontend2/components/administration/users/roles/bucket-roles-roles-column', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    // initially calculate the role
    role: _ember['default'].computed('row.userModel', 'row.content.bucket_user_roles.[]', 'row.content.bucket_user_roles.@each.isDeleted', function () {
      var bucketId, bucketUserRole, userId;
      // get the user id, might be null, if so cancel processing
      userId = this.get('row.userModel.id');
      if (userId == null) {
        return '';
      }
      // determine the role
      bucketId = this.get('row.content.id');
      bucketUserRole = this.get('store').peekAll('bucket-user-role').find(function (item) {
        return item.get('bucket.id') === bucketId && item.get('user.id') === userId && item.get('isDeleted') === false;
      });
      if (bucketUserRole) {
        return this.get('i18n').t('user.roles.' + bucketUserRole.get('role.name') + '.short');
      } else {
        return '-';
      }
    })
  });
});