define("frontend2/initializers/money-setup", ["exports", "accounting/settings"], function (exports, _accountingSettings) {
  ;
  var MoneyInitializer;

  MoneyInitializer = {
    name: 'Money',
    initialize: function initialize() {
      _accountingSettings.currency.symbol = "€";
      _accountingSettings.number.decimal = ",";
      return _accountingSettings.number.thousand = ".";
    }
  };

  exports["default"] = MoneyInitializer;
});