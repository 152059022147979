define('frontend2/controllers/user/edit-profile', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  ;
  ;
  var UserEditProfileController;

  UserEditProfileController = _ember['default'].Controller.extend(_emberValidations['default'], {
    i18n: _ember['default'].inject.service(),
    is_not_valid: _ember['default'].computed.not('isValid'),
    is_not_dirty: _ember['default'].computed.not('model.hasDirtyAttributes'),
    headerLayout: 'header-layouts/header-content-box',
    is_submit_button_disabled: (function () {
      return this.get('is_not_dirty') || this.get('is_not_valid');
    }).property('model.hasDirtyAttributes', 'isValid'),
    set_validation_messages: (function () {
      this.get('validations')['model.first_name']['presence']['message'] = this.get('i18n').t("validation_messages.first_name.required").toString();
      this.get('validations')['model.last_name']['presence']['message'] = this.get('i18n').t("validation_messages.last_name.required").toString();
      return this.get('validations')['model.display_name']['presence']['message'] = this.get('i18n').t("validation_messages.display_name.required").toString();
    }).on('init'),
    validations: {
      'model.first_name': {
        presence: {
          message: ''
        }
      },
      'model.last_name': {
        presence: {
          message: ''
        }
      },
      'model.display_name': {
        presence: {
          message: ''
        }
      }
    }
  });

  exports['default'] = UserEditProfileController;
  ;
});