define('frontend2/mixins/table-commons', ['exports', 'ember', 'ember-light-table', 'ember-concurrency'], function (exports, _ember, _emberLightTable, _emberConcurrency) {
  var Mixin, computed, inject, isEmpty;

  inject = _ember['default'].inject;
  isEmpty = _ember['default'].isEmpty;
  computed = _ember['default'].computed;
  Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    store: inject.service(),
    page: 0,
    limit: 25,
    dir: 'asc',
    sort: 'id',
    where: null,
    isLoading: computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,
    modelName: null,
    model: null,
    meta: null,
    columns: null,
    table: null,
    init: function init() {
      var sortColumn, table;
      this._super.apply(this, arguments);
      this.set('model', Em.A());
      // create the table, use columns, the model and enableSync
      table = new _emberLightTable['default'](this.get('columns'), this.get('model'), {
        enableSync: this.get('enableSync')
      });
      sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }
      return this.set('table', table);
    },
    fetchRecords: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var queryParams, records;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            queryParams = {
              sort: this.get('sort') + ' ' + this.get('dir'),
              skip: (Number(this.get('page')) - 1) * Number(this.get('limit')),
              limit: this.get('limit')
            };
            if (this.get('where')) {
              queryParams.where = this.get('where');
            }
            // return if @get('modelName') == 'bucket'
            context$1$0.next = 4;
            return this.get('store').query(this.get('modelName'), queryParams);

          case 4:
            records = context$1$0.sent;

            this.get('model').pushObjects(records.toArray());
            this.set('meta', records.get('meta'));
            return context$1$0.abrupt('return', this.set('canLoadMore', !isEmpty(records)));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),
    _obReload: _ember['default'].observer('reload', function () {
      return this._reset();
    }),
    _reset: function _reset() {
      var _this = this;

      var timeoutTime = arguments.length <= 0 || arguments[0] === undefined ? 2000 : arguments[0];

      return setTimeout(function () {
        _this.setProperties({
          canLoadMore: true,
          page: 0
        });
        return _this.get('model').clear();
      }, timeoutTime);
    },
    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.incrementProperty('page');
          return this.get('fetchRecords').perform();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });
          return this.get('model').clear();
        }
      }
    }
  });
});