define('frontend2/services/url', ['exports', 'ember'], function (exports, _ember) {
  var UrlService;

  UrlService = _ember['default'].Service.extend({
    href: '',
    lastRouteName: '',
    lastRouteUrl: '',
    href_encoded: (function () {
      return encodeURIComponent(this.href);
    }).property('href'),
    _setup: (function () {
      return this.set('href', window.location.href);
    }).on('init')
  });

  exports['default'] = UrlService;
});