define('frontend2/routes/users/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  ;
  ;
  var UsersLoginRoute;

  UsersLoginRoute = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    notify: _ember['default'].inject.service(),
    authorization: _ember['default'].inject.service(),
    url: _ember['default'].inject.service(),
    user: _ember['default'].inject.service(),
    model: function model() {
      return new _ember['default'].RSVP.Promise(function (resolve) {
        return resolve(_ember['default'].Object.create({
          email: '',
          password: ''
        }));
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var standardBucketId, url;
        if (!this.get('transitioning')) {
          transition.abort();
          url = this.get('url.lastRouteUrl');
          if (url && !url.match(/.*buckets\/index$/)) {
            // hack to go to the previous URL
            this.set('transitioning', true);
            location.hash = url;
            return window.location.reload();
          } else {
            standardBucketId = this.get('user.model.standard_bucket.id');
            this.set('transitioning', true);
            if (standardBucketId) {
              return this.transitionTo('buckets.show', standardBucketId);
            } else {
              return this.transitionTo('buckets.index');
            }
          }
        }
      },
      local_authentication: function local_authentication() {
        var _this, credentials;
        credentials = this.currentModel;
        _this = this;
        this.get('session').authenticate('authenticator:local', credentials)['catch'](function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      facebook_authentication: function facebook_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('simple-auth-authenticator:facebook')['catch'](function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      google_authentication: function google_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('simple-auth-authenticator:google')['catch'](function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      },
      twitter_authentication: function twitter_authentication() {
        var _this;
        _this = this;
        this.get('session').authenticate('simple-auth-authenticator:twitter')['catch'](function (error_message) {
          return _this.get('notify').alert(error_message, {
            closeAfter: 0
          });
        });
      }
    }
  });

  exports['default'] = UsersLoginRoute;
  ;
});