define("frontend2/validators/patterns", ["exports"], function (exports) {
  var Pattern;

  Pattern = Ember.Namespace.create({
    // The Official Standard: RFC 5322 -> The Practical Implementation
    // http://www.regular-expressions.info/email.html
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  });

  exports["default"] = Pattern;
  ;
});