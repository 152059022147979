define('frontend2/router', ['exports', 'ember'], function (exports, _ember) {
  ;
  var Router, _generateMaintenanceView;

  Router = _ember['default'].Router.extend({
    url: _ember['default'].inject.service(),
    location: 'hashbang',
    onDidTransitionUpdateUrl: _ember['default'].on('didTransition', function () {
      var url_service;
      url_service = this.get('url');
      return url_service.set('href', window.location.href);
    }),
    onWillTransition: _ember['default'].on('willTransition', function () {
      this.set('url.lastRouteName', this.currentRouteName);
      return this.set('url.lastRouteUrl', location.hash);
    })
  });

  _generateMaintenanceView = function (nameOfResource) {
    return this.route(nameOfResource, function () {
      return this.route('index', function () {
        this.route('create');
        return this.route('edit', {
          path: ':id/edit'
        });
      });
    });
  };

  Router.map(function () {
    this.route('users', {
      resetNamespace: true
    }, function () {
      this.route('login');
      return this.route('password-reset');
    });
    this.route('user', function () {
      this.route('profile', function () {
        this.route('edit-profile');
        this.route('edit-organization');
        return this.route('change-password');
      });
      return this.route('upgrade', {
        path: '/upgrade/:plan_id'
      });
    });
    this.route('buckets', function () {
      this.route('search', function () {
        this.route('upload', {
          path: 'upload/:uploadId'
        });
        this.route('share', {
          path: 'share/:uploadId'
        });
        this.route('share-with-publisher', {
          path: 'share-with-publisher/:uploadId'
        });
        return this.route('give-feedback', {
          path: 'give-feedback/:uploadId'
        });
      });
      this.route('index', function () {
        return this.route('create');
      });
      return this.route('show', {
        path: ':bucket_id/show'
      }, function () {
        this.route('upload', {
          path: 'upload/:uploadId'
        });
        this.route('settings');
        this.route('share', {
          path: 'share/:uploadId'
        });
        this.route('share-with-publisher', {
          path: 'share-with-publisher/:uploadId'
        });
        this.route('give-feedback', {
          path: 'give-feedback/:uploadId'
        });
        return this.route('publish');
      });
    });
    this.route('help');
    return this.route('administration', {
      resetNamespace: true
    }, function () {
      this.route('users', function () {
        return this.route('index', function () {
          this.route('create');
          return this.route('roles', {
            path: ':user_id/roles'
          });
        });
      });
      _generateMaintenanceView.call(this, 'publishers');
      _generateMaintenanceView.call(this, 'imagesizes');
      _generateMaintenanceView.call(this, 'issue-numbers');
      _generateMaintenanceView.call(this, 'titles');
      _generateMaintenanceView.call(this, 'authors');
      _generateMaintenanceView.call(this, 'pos');
      _generateMaintenanceView.call(this, 'upload-common-areas');
      _generateMaintenanceView.call(this, 'upload-common-categories');
      return _generateMaintenanceView.call(this, 'upload-common-commodity-groups');
    });
  });

  // temporarily disabled
  // _generateMaintenanceView.call @, 'national-distributors'
  exports['default'] = Router;
  ;
});