define('frontend2/helpers/promise-all', ['exports', 'ember-promise-helpers/helpers/promise-all'], function (exports, _emberPromiseHelpersHelpersPromiseAll) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersPromiseAll['default'];
    }
  });
  Object.defineProperty(exports, 'promiseAll', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersPromiseAll.promiseAll;
    }
  });
});