define('frontend2/components/drm/progress-bar', ['exports'], function (exports) {
  var ProgressBarComponent;

  ProgressBarComponent = Ember.Component.extend({
    classNames: 'progress-bar__wrapper',
    colorClass: Ember.computed('percentage', function () {
      var percentage;
      percentage = this.get('percentage');
      switch (false) {
        case !(percentage > 80):
          return 'progress-danger';
        case !(percentage > 50):
          return 'progress-warning';
        case !(percentage >= 0):
          return 'progress-success';
      }
    })
  });

  exports['default'] = ProgressBarComponent;
});