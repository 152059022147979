define('frontend2/models/ext/upload-mixin', ['exports', 'frontend2/models/ext/enumerations'], function (exports, _frontend2ModelsExtEnumerations) {
  exports['default'] = Ember.Mixin.create({
    userService: Ember.inject.service('user'),
    division: DS.attr('number', {
      defaultValue: 0
    }),
    publisher: DS.belongsTo('publisher'),
    author: DS.belongsTo('author'),
    title: DS.belongsTo('title'),
    issue_number: DS.attr('number'),
    issue_number_year: DS.attr('number', {
      defaultValue: function defaultValue() {
        return moment().format('YYYY');
      }
    }),
    is_top: DS.attr('boolean'),
    start_week: DS.attr('number', {
      defaultValue: function defaultValue() {
        return moment().week();
      }
    }),
    duration: DS.attr('number', {
      defaultValue: 1
    }),
    pos: DS.belongsTo('pos'),
    shared: DS.attr('boolean', {
      defaultValue: false
    }),
    user: DS.belongsTo('user'),
    upload_type: DS.attr('number', {
      defaultValue: 0
    }),
    upload_common_area: DS.belongsTo('upload-common-area'),
    upload_common_category: DS.belongsTo('upload-common-category'),
    upload_common_commodity_group: DS.belongsTo('upload-common-commodity-group'),
    is_association_booking: DS.attr('boolean', {
      defaultValue: false
    }),
    is_association_premium_booking: DS.attr('boolean', {
      defaultValue: false
    }),
    date: DS.attr('date'),
    updated_at: DS.attr('date'),
    created_at: DS.attr('date'),
    isPress: Ember.computed.equal('division', _frontend2ModelsExtEnumerations['default'].divisions.press),
    isBook: Ember.computed.equal('division', _frontend2ModelsExtEnumerations['default'].divisions.book),
    isPoSUpload: Ember.computed.equal('upload_type', 1),
    isCommonUpload: Ember.computed.equal('upload_type', 2)
  });
});