define('frontend2/routes/buckets/share-with-publisher-mixin', ['exports'], function (exports) {
  exports['default'] = Ember.Mixin.create({
    notify: Ember.inject.service(),
    model: function model(params) {
      var modelProperties, publisherId, upload, uploads, uploadsThatShareFirstPublisher, uploadsThatShareFirstTop;
      modelProperties = {
        publisher: null,
        uploadSample: null,
        ccReceivers: '',
        uploads: [],
        comment: ''
      };
      if (params.uploadId === 'selected') {
        // multi share mode
        uploads = this.get('store').peekAll('upload').filterBy('selected', true);
        modelProperties.uploads = uploads;
        if (uploads.get('length') > 0) {
          modelProperties.uploadSample = uploads[0];
          // check that only one publisher is selected
          publisherId = uploads[0].get('publisher.id');
          uploadsThatShareFirstPublisher = uploads.filterBy('publisher.id', publisherId);
          uploadsThatShareFirstTop = uploads.filterBy('is_top', uploads[0].get('is_top'));
          if (uploads.get('length') !== uploadsThatShareFirstPublisher.get('length')) {
            this.get('notify').info('Für diese Funktion dürfen nur Bilder eines einzigen Verlags markiert sein');
            return this.transitionTo('buckets.show');
          } else if (uploads.get('length') !== uploadsThatShareFirstTop.get('length')) {
            this.get('notify').info('Entweder müssen alle oder keine Bilder der ausgewählten TOP-Buchungen sein.');
            return this.transitionTo('buckets.show');
          } else {
            modelProperties.publisher = this.get('store').peekRecord('publisher', publisherId);
          }
        } else if (params.uploadId) {
          this.get('notify').info('Bitte zunächst Bilder markieren.');
          return this.transitionTo('buckets.show');
        }
      } else {
        // single share mode
        upload = this.get('store').peekRecord('upload', params.uploadId);
        modelProperties.uploadSample = upload;
        modelProperties.uploads = [upload];
        modelProperties.publisher = upload.get('publisher');
      }
      if (!modelProperties.uploadSample || !modelProperties.publisher) {
        this.get('notify').error('Es kam zu einem Fehler. Bitte wenden Sie sich an den Administrator.');
        return this.transitionTo('buckets.show');
      }
      return Ember.Object.create(modelProperties);
    }
  });
});