define('frontend2/components/ext/upload-form-store', ['exports', 'ember-concurrency', 'frontend2/models/ext/enumerations'], function (exports, _emberConcurrency, _frontend2ModelsExtEnumerations) {
  exports['default'] = Ember.Component.extend({
    store: Ember.inject.service(),
    enumerations: _frontend2ModelsExtEnumerations['default'],
    upload: null,
    valuesHelp: Ember.Object.create({
      publishers: [],
      pos: [],
      areas: [],
      categories: [],
      commodityGroups: []
    }),
    authorQuery: '',
    init: function init() {
      this._super();
      this._findAllModelsByDivision();
      return this._findAllModels();
    },
    _findAllModelsByDivision: function _findAllModelsByDivision() {
      this.get('findModelsByDivision').perform('publisher', 'publishers');
      return this.get('findModelsByDivision').perform('pos', 'pos');
    },
    _findAllModels: function _findAllModels() {
      return regeneratorRuntime.async(function _findAllModels$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.t0 = this;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('store').findAll('upload-common-area'));

          case 3:
            context$1$0.t1 = context$1$0.sent;
            context$1$0.t0.set.call(context$1$0.t0, 'valuesHelp.areas', context$1$0.t1);
            context$1$0.t2 = this;
            context$1$0.next = 8;
            return regeneratorRuntime.awrap(this.get('store').findAll('upload-common-category'));

          case 8:
            context$1$0.t3 = context$1$0.sent;
            context$1$0.t2.set.call(context$1$0.t2, 'valuesHelp.categories', context$1$0.t3);
            context$1$0.t4 = this;
            context$1$0.next = 13;
            return regeneratorRuntime.awrap(this.get('store').findAll('upload-common-commodity-group'));

          case 13:
            context$1$0.t5 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t4.set.call(context$1$0.t4, 'valuesHelp.commodityGroups', context$1$0.t5));

          case 15:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    findModelsByDivision: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(model, property) {
      var records;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('store').query(model, {
              division: this.get('upload.division')
            });

          case 2:
            records = context$1$0.sent;
            return context$1$0.abrupt('return', this.set('valuesHelp.' + property, records.toArray()));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    searchTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(modelName, term) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(250);

          case 2:
            context$1$0.next = 4;
            return this.get('store').query(modelName, {
              where: {
                name: {
                  contains: term
                }
              }
            });

          case 4:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),
    _obDivision: Ember.observer('upload.division', function () {
      return this._findAllModelsByDivision();
    }),
    _obImageType: Ember.observer('upload.upload_type', function () {
      if (this.get('upload.isCommonUpload')) {
        return this._findAllModels();
      }
    }),
    actions: {
      create: function create(modelName, dropdown, event) {
        var newAuthor, yesNo;
        return regeneratorRuntime.async(function create$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              if (!(event.key !== 'Enter')) {
                context$1$0.next = 2;
                break;
              }

              return context$1$0.abrupt('return');

            case 2:
              if (!(dropdown.searchText.trim().length === 0)) {
                context$1$0.next = 4;
                break;
              }

              return context$1$0.abrupt('return', false);

            case 4:
              if (!dropdown.loading) {
                context$1$0.next = 6;
                break;
              }

              return context$1$0.abrupt('return', false);

            case 6:
              if (!(dropdown.resultsCount === 0)) {
                context$1$0.next = 14;
                break;
              }

              yesNo = confirm('Soll ein Eintrag mit Namen ' + dropdown.searchText + ' erstellt werden?');

              if (!yesNo) {
                context$1$0.next = 14;
                break;
              }

              newAuthor = this.get('store').createRecord(modelName, {
                name: dropdown.searchText
              });
              context$1$0.next = 12;
              return regeneratorRuntime.awrap(newAuthor.save());

            case 12:
              dropdown.actions.select(newAuthor);
              return context$1$0.abrupt('return', dropdown.actions.close());

            case 14:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      changeProperty: function changeProperty(whichProperty, changeData) {
        return this.set('upload.' + whichProperty, changeData.value);
      }
    }
  });
});

// ignore all keys but Enter

// ignore empty input

// ignore enter in loading state

// if no results try to create the model