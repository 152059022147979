define('frontend2/components/ext/nd-select', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  exports['default'] = Ember.Component.extend({
    nationalDistributors: [],
    store: Ember.inject.service(),
    init: function init() {
      this._super();
      return this.get('findAll').perform();
    },
    findAll: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var records;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('store').findAll('national-distributor');

          case 2:
            records = context$1$0.sent;
            return context$1$0.abrupt('return', this.set("nationalDistributors", records.toArray()));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    }))
  });
});