define('frontend2/instance-initializers/create-maintenance-view-validations', ['exports', 'ember-validations', 'lodash'], function (exports, _emberValidations, _lodash) {
  var Initializer, initialize;

  exports.initialize = initialize = function (app) {
    var controller, controllerName, controllerNames, maintenanceViews, name, nameRule, results, rules;
    controllerNames = ['edit', 'create'];
    nameRule = {
      'model.name': {
        presence: {
          message: 'Name muss vorhanden sein'
        },
        length: {
          minimum: 2,
          messages: {
            tooShort: 'Mindestens zwei Zeichen werden benötigt.'
          }
        }
      }
    };
    maintenanceViews = {
      titles: nameRule,
      'issue-numbers': nameRule,
      authors: nameRule,
      pos: nameRule,
      publishers: nameRule,
      'upload-common-areas': nameRule,
      'upload-common-categories': nameRule,
      'upload-common-commodity-groups': nameRule
    };
    results = [];
    for (name in maintenanceViews) {
      rules = maintenanceViews[name];
      results.push((function () {
        var i, len, results1;
        results1 = [];
        for (i = 0, len = controllerNames.length; i < len; i++) {
          controllerName = controllerNames[i];
          controller = app.resolveRegistration('controller:administration/' + name + '/index/' + controllerName);
          results1.push(controller.reopen(_emberValidations['default'], {
            validations: rules
          }));
        }
        return results1;
      })());
    }
    return results;
  };

  Initializer = {
    name: 'create-maintenance-view-validations',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports['default'] = Initializer;
});