define('frontend2/initializers/body-class', ['exports'], function (exports) {
  // Takes two parameters: container and application
  var BodyClassInitializer, initialize;

  exports.initialize = initialize = function () {
    return Ember.Route.reopen({
      activate: function activate() {
        return Ember.$('body').attr('class', 'route--' + this.routeName.replace(/\./g, '-').dasherize());
      }
    });
  };

  BodyClassInitializer = {
    name: 'body-class',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports['default'] = BodyClassInitializer;
});