define('frontend2/routes/buckets/give-feedback-mixin', ['exports'], function (exports) {
  exports['default'] = Ember.Mixin.create({
    model: function model(params) {
      var numberOfImages, uploads;
      numberOfImages = 1;
      if (params.uploadId === 'selected') {
        uploads = this.get('store').peekAll('upload').filterBy('selected', true);
        numberOfImages = uploads.length;
      }
      return Ember.Object.create({
        uploadId: params.uploadId,
        numberOfImages: numberOfImages,
        feedback: ''
      });
    }
  });
});