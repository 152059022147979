define('frontend2/components/ext/calendar-duration', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    durations: [],
    duration: 1,
    init: function init() {
      this._super();
      return this.set('durations', [1, 2, 3, 4, 5, 6, 7, 8].map(function (i) {
        return {
          value: i,
          label: i + ' Woche' + (i > 1 ? 'n' : '')
        };
      }));
    }
  });
});