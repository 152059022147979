define('frontend2/models/tag-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  var TagUpload;

  TagUpload = _emberData['default'].Model.extend({
    tag: _emberData['default'].belongsTo('tag', {
      async: true
    }),
    upload: _emberData['default'].belongsTo('upload', {
      async: true
    })
  });

  exports['default'] = TagUpload;
});