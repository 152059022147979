define('frontend2/helpers/promise-hash', ['exports', 'ember-promise-helpers/helpers/promise-hash'], function (exports, _emberPromiseHelpersHelpersPromiseHash) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersPromiseHash['default'];
    }
  });
  Object.defineProperty(exports, 'promiseHash', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersPromiseHash.promiseHash;
    }
  });
});