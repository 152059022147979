define('frontend2/components/drm/media-upload-standard', ['exports', 'ember-validations', 'frontend2/components/drm/media-upload'], function (exports, _emberValidations, _frontend2ComponentsDrmMediaUpload) {
  var UPLOAD_NAME_MIN_CHARS;

  UPLOAD_NAME_MIN_CHARS = 2;

  exports['default'] = _frontend2ComponentsDrmMediaUpload['default'].extend(_emberValidations['default'], {
    init: function init() {
      return this._super();
    },
    set_validation_messages: (function () {
      this.get('validations')['upload.name']['presence']['message'] = this.get('i18n').t("validation_messages.presence").toString();
      return this.get('validations')['upload.name']['length']['messages']['tooShort'] = this.get('i18n').t("validation_messages.length.tooShort", {
        count: UPLOAD_NAME_MIN_CHARS
      }).toString();
    }).on('init'),
    validations: {
      'upload.name': {
        presence: {
          message: ''
        },
        length: {
          minimum: UPLOAD_NAME_MIN_CHARS,
          messages: {
            tooShort: ''
          }
        }
      }
    }
  });
});