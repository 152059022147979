define('frontend2/controllers/administration/users/index/create', ['exports', 'ember-validations', 'frontend2/validators/patterns'], function (exports, _emberValidations, _frontend2ValidatorsPatterns) {
  ;
  ;
  var ExController;

  ExController = Ember.Controller.extend(_emberValidations['default'], {
    assignedRoles: [],
    set_validation_messages: (function () {
      this.get('validations')['model.company']['presence']['message'] = this.get('i18n').t("validation_messages.presence").toString();
      this.get('validations')['model.email']['presence']['message'] = this.get('i18n').t("validation_messages.email.required").toString();
      return this.get('validations')['model.email']['format']['message'] = this.get('i18n').t("validation_messages.email.invalid_format").toString();
    }).on('init'),
    validations: {
      'model.company': {
        presence: {
          message: ''
        }
      },
      'model.email': {
        presence: {
          message: ''
        },
        format: {
          'with': _frontend2ValidatorsPatterns['default'].email,
          message: ''
        }
      }
    }
  });

  exports['default'] = ExController;
});