define('frontend2/adapters/application', ['exports', 'ember-data-sails/adapters/sails-socket'], function (exports, _emberDataSailsAdaptersSailsSocket) {
  ;
  var SocketAdapter;

  SocketAdapter = _emberDataSailsAdaptersSailsSocket['default'].extend({
    // Whether to use CSRF tokens or not
    useCSRF: false,
    // Whether to group multiple find by ID with one request with a `where`
    coalesceFindRequests: true,
    //The namespace of your API
    namespace: 'api',
    // If you want to use https://github.com/mphasize/sails-generate-ember-blueprints,
    // you need to override the default serializer to be used
    defaultSerializer: '-rest',
    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },
    custom_rest: function custom_rest(model, values_for_update, endpoint) {
      var _this = this;

      var method = arguments.length <= 3 || arguments[3] === undefined ? "PUT" : arguments[3];

      var data, type_key, url;
      type_key = model.constructor.modelName;
      url = this.buildURL(type_key) + '/' + model.id + '/' + endpoint;
      data = {};
      data[type_key] = values_for_update;
      model.setProperties(values_for_update);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.ajax(url, method, {
          data: data
        }).then(function (response) {
          model.set('currentState', DS.RootState.loaded.saved);
          return resolve(response);
        }, function (error) {
          return reject(error);
        });
      });
    },
    // overrides the hook, takes the modelName and transforms it into a path in the
    // url, e.g. book to books (later e.g. api/books). For simple stuff that works
    // fine but for e.g. contact-form the standard would create contactForm.
    // With Sailsjs in the backend and the model name Contact_Form the path
    // has to be contact_form, so we underscore and pluralize now.
    pathForType: function pathForType(modelName) {
      return Ember.String.pluralize(Ember.String.underscore(modelName));
    },
    findMany: function findMany(store, type, ids, snapshots) {
      var url;
      url = this.buildURL(type.modelName, null, snapshots, 'findMany');
      return this.ajax(url, 'GET', {
        data: {
          ids: ids
        }
      });
    }
  });

  exports['default'] = SocketAdapter;
  ;
});
// file: app/adapters/application.js