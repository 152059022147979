define('frontend2/models/plan', ['exports', 'ember-data'], function (exports, _emberData) {
  var Plan;

  Plan = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    price: _emberData['default'].attr('number'),
    idx: _emberData['default'].attr('number'),
    size: _emberData['default'].attr('number'),
    plan_features: _emberData['default'].hasMany('plan-feature'),
    amount: Ember.computed('price', function () {
      return this.get('price') / 100;
    })
  });

  exports['default'] = Plan;
});