define('frontend2/models/user-role', ['exports', 'ember-data'], function (exports, _emberData) {
  var UserRole;

  UserRole = _emberData['default'].Model.extend({
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    role: _emberData['default'].belongsTo('role', {
      async: true
    })
  });

  exports['default'] = UserRole;
});