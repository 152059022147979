define('frontend2/helpers/get-option', ['exports'], function (exports) {
  var GetOptionHelper;

  GetOptionHelper = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute: function compute(params) {
      var option, options;
      options = this.get('store').peekAll('option');
      option = options.findBy('key', params[0]);
      if (option) {
        return option.get('value');
      } else {
        return '';
      }
    }
  });

  exports['default'] = GetOptionHelper;
});