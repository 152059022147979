define('frontend2/serializers/user', ['exports', 'frontend2/serializers/application'], function (exports, _frontend2SerializersApplication) {
  exports['default'] = _frontend2SerializersApplication['default'].extend({
    serialize: function serialize(snapshot, options) {
      var i, json, len, ref, ref1, role, roleData;
      json = this._super(snapshot, options);
      if (((ref = snapshot.adapterOptions) != null ? ref.roles : void 0) != null) {
        roleData = [];
        ref1 = snapshot.adapterOptions.roles;
        for (i = 0, len = ref1.length; i < len; i++) {
          role = ref1[i];
          roleData.push(role.serialize({
            includeId: true
          }));
        }
        json.roles = roleData;
      }
      return json;
    }
  });
});