define('frontend2/routes/buckets/search-show-share-mixin', ['exports'], function (exports) {
  exports['default'] = Ember.Mixin.create({
    model: function model(params) {
      var newObjectProperties;
      newObjectProperties = params;
      newObjectProperties.email = '';
      newObjectProperties.comment = '';
      return Ember.Object.create(newObjectProperties);
    }
  });
});