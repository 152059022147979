define('frontend2/routes/buckets/search-show-upload-mixin', ['exports', 'frontend2/config/environment'], function (exports, _frontend2ConfigEnvironment) {
  exports['default'] = Ember.Mixin.create({
    overlay: Ember.inject.service(),
    upload: Ember.inject.service(),
    model: function model(params) {
      var bucket, isAssociationBooking, uploadType;
      if ('uploadId' in params && params.uploadId !== 'new') {
        return this.get('store').findRecord('upload', params.uploadId);
      } else {
        bucket = this.modelFor('buckets.show');
        uploadType = bucket.get('isStandard') ? 0 : 1;
        isAssociationBooking = bucket.get('isExternal');
        return this.get('store').createRecord('upload', {
          bucket: bucket,
          upload_type: uploadType,
          is_association_booking: isAssociationBooking
        });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      return controller.set('licenseTypes', this.get('store').peekAll('option').filterBy('key', 'license-type').mapBy('value'));
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        var close;
        if (_this.currentModel.get('isNew') && Object.keys(_this.currentModel.changedAttributes()).length > 0) {
          close = confirm(_this.get('i18n').t('actions.confirm.changesAreLost').toString());
          if (!close) {
            return false;
          }
        }
        if (_this.currentModel.get('isNew')) {
          _this.currentModel.deleteRecord();
        }
        return _this.onBack();
      });
    },
    deactivate: function deactivate() {
      return this.get('overlay').hide();
    },
    actions: {
      cancel: function cancel() {
        var _this2 = this;

        var shouldRollback;
        if (this.currentModel.get('hasDirtyAttributes') || this.currentModel.hasTemporaryFile) {
          shouldRollback = confirm(this.get('i18n').t('actions.confirm.changesAreLost').toString());
          if (shouldRollback && !this.currentModel.get('isNew')) {
            this.currentModel.rollbackAttributes();
            // delete temporary uploaded file (and model), if it exists
            // -> set temporary_id to -1 and save model
            // -> check in backend: if temporary_id = -1 AND a temporaray model exists,
            //                      which belongs to this one, then delete it
            if (this.currentModel.hasTemporaryFile) {
              this.currentModel.set('temporary_id', -1);
            }
            this.currentModel.save().then(function () {
              _this2.currentModel.set('hasTemporaryFile', false);
              return _this2.onBack();
            });
          }
          if (shouldRollback && this.currentModel.get('isNew')) {
            this.currentModel.destroyRecord();
            return this.onBack();
          }
        } else {
          return this.onBack();
        }
      },
      replaceFile: function replaceFile(file) {
        var _this3 = this;

        var bucket;
        this.currentModel.set('hasTemporaryFile', false);
        bucket = this.modelFor('buckets.show');
        return this.get('upload').saveUpload('media', file, bucket.get('id'), false, this.currentModel.get('id')).then(function (temporaryUpload) {
          console.log("url of temp file: " + temporaryUpload.upload_json.url);
          _this3.currentModel.set('hasTemporaryFile', true);
          _this3.currentModel.set('tempId', temporaryUpload.upload_json.id);
          _this3.currentModel.set('temporaryUploadModelJson', temporaryUpload.upload_json);
          _this3.currentModel.set('temporary_id', temporaryUpload.upload_json.id);
          _this3.currentModel.save();
          return _this3.currentModel.set('_wasReplaced', true);
        });
      },
      getUrl: function getUrl(upload, imagesizeId, close) {
        if (location.host.match(/localhost/)) {
          window.prompt("Public URL:", '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/uploads/public/' + upload.id + '/' + imagesizeId);
        } else {
          window.prompt("Public URL:", location.host + '/uploads/public/' + upload.id + '/' + imagesizeId);
        }
        close();
        return false;
      },
      download: function download(upload, imagesizeId, close) {
        window.location = upload.get('download_link') + '&imagesize_id=' + imagesizeId;
        close();
        return false;
      },
      onAssociationBookingClicked: function onAssociationBookingClicked(event) {
        var isAssociationBooking, isAssociationPremiumBooking, model;
        model = this.get('controller.model');
        isAssociationPremiumBooking = model.get("is_association_premium_booking");
        isAssociationBooking = !model.get("is_association_booking");
        model.set("is_association_booking", isAssociationBooking);
        if (!isAssociationBooking && isAssociationPremiumBooking) {
          return model.set("is_association_premium_booking", false);
        }
      },
      onAssociationPremiumBookingClicked: function onAssociationPremiumBookingClicked() {
        var isAssociationBooking, isAssociationPremiumBooking, model;
        model = this.get('controller.model');
        isAssociationBooking = model.get("is_association_booking");
        isAssociationPremiumBooking = !model.get("is_association_premium_booking");
        model.set("is_association_premium_booking", isAssociationPremiumBooking);
        if (!isAssociationBooking && isAssociationPremiumBooking) {
          return model.set("is_association_booking", true);
        }
      },
      filterPosByName: function filterPosByName(name) {
        return name === 'Schaufenster' || name === 'Instore Sonderplatzierung';
      }
    }
  });
});