define('frontend2/helpers/uppercase', ['exports', 'ember-cli-string-helpers/helpers/uppercase'], function (exports, _emberCliStringHelpersHelpersUppercase) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersUppercase['default'];
    }
  });
  Object.defineProperty(exports, 'uppercase', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersUppercase.uppercase;
    }
  });
});