define('frontend2/components/administration/users-table', ['exports', 'ember', 'ember-light-table', 'frontend2/mixins/table-commons'], function (exports, _ember, _emberLightTable, _frontend2MixinsTableCommons) {
  exports['default'] = _ember['default'].Component.extend(_frontend2MixinsTableCommons['default'], {
    pageBody: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    modelName: 'user',
    columns: _ember['default'].computed(function () {
      var i18n;
      i18n = this.get('i18n');
      return [{
        label: i18n.t('drm.displayName'),
        valuePath: 'display_name'
      }, {
        label: i18n.t('drm.email'),
        valuePath: 'email'
      }, {
        label: i18n.t('drm.role'),
        valuePath: 'roles',
        sortable: false,
        cellComponent: 'administration/users-table-roles-column'
      }, {
        label: i18n.t('drm.models.user.active'),
        valuePath: 'active',
        cellComponent: 'ui/col-boolean'
      }, {
        label: '',
        sortable: false,
        cellComponent: 'administration/users-table-actions-column',
        cellClassNames: ['table__actions'],
        width: '100px'
      }];
    })
  });
});