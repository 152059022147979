define("frontend2/models/ext/enumerations", ["exports"], function (exports) {
  exports["default"] = {
    genders: {
      male: 0,
      female: 1
    },
    divisions: {
      book: 0,
      press: 1
    },
    notificationReceiver: {
      publisher: 0,
      nationalDistributor: 1
    }
  };
});