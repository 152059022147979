define('frontend2/routes/buckets/index', ['exports', 'lodash'], function (exports, _lodash) {
  var BucketsIndexRoute;

  BucketsIndexRoute = Ember.Route.extend({
    url: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var standardBucket;
      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!(this.get('url.lastRouteName') === 'users.login')) {
              context$1$0.next = 6;
              break;
            }

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('user.model.standard_bucket'));

          case 3:
            standardBucket = context$1$0.sent;

            if (!standardBucket) {
              context$1$0.next = 6;
              break;
            }

            return context$1$0.abrupt('return', this.transitionTo('buckets.show', this.get('user.model.standard_bucket')));

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    model: function model() {
      return this.store.findAll('bucket');
    },
    actions: {
      deleteBucket: function deleteBucket() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['deleteBucketGlobal'].concat(args));
        return this.controller.incrementProperty('reload');
      }
    }
  });

  exports['default'] = BucketsIndexRoute;
});