define('frontend2/routes/buckets/show/give-feedback', ['exports', 'frontend2/routes/buckets/give-feedback-mixin', 'frontend2/routes/buckets/overlay-routing-mixin-factory'], function (exports, _frontend2RoutesBucketsGiveFeedbackMixin, _frontend2RoutesBucketsOverlayRoutingMixinFactory) {
  var OverlayRoutingMixin;

  OverlayRoutingMixin = (0, _frontend2RoutesBucketsOverlayRoutingMixinFactory['default'])('buckets.show');

  exports['default'] = Ember.Route.extend(_frontend2RoutesBucketsGiveFeedbackMixin['default'], OverlayRoutingMixin, {
    actions: {
      formSubmitted: function formSubmitted() {
        this.send('giveFeedback', this.modelFor(this.routeName));
        this.transitionTo('buckets.show');
        return false;
      }
    }
  });
});