define('frontend2/services/upload', ['exports', 'ember', 'frontend2/config/environment'], function (exports, _ember, _frontend2ConfigEnvironment) {
  /*
   * This service is used to upload images to the server and push the results into the store and the corresponding model.
   * If a model is using this service it MUST implement the get_book_id() Method
   */
  var UploadService;

  UploadService = _ember['default'].Service.extend({
    isUploading: false,
    uploadProgress: 0,
    inQueue: 0,
    //# SERVICES ETC. ###
    store: _ember['default'].inject.service(),
    saveUpload: function saveUpload(name, file, bucket_id, getAutoInfos) {
      var _this = this;

      var upload_id = arguments.length <= 4 || arguments[4] === undefined ? -1 : arguments[4];

      this.set('uploadProgress', 0);
      this.set('isUploading', true);
      this.incrementProperty('inQueue');
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        return _ember['default'].run.next(_this, function () {
          var fd;
          fd = new FormData();
          fd.append('bucket_id', bucket_id);
          fd.append('upload_for', name);
          fd.append('upload_id', upload_id);
          fd.append('auto_description', getAutoInfos);
          fd.append('file', file);
          // upload the form data
          return $.ajax({
            url: '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/upload',
            data: fd,
            processData: false,
            contentType: false,
            type: 'POST',
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            },
            xhr: function xhr() {
              var xhr;
              xhr = new window.XMLHttpRequest();
              xhr.upload.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                  return _this.set('uploadProgress', Math.floor(evt.loaded / evt.total * 100));
                }
              }, false);
              // xhr.addEventListener "progress", (evt) ->
              //   if evt.lengthComputable
              //    percentComplete = evt.loaded / evt.total;
              //    console.log "Progress2: #{percentComplete}"
              // , false
              return xhr;
            },
            success: function success(r) {
              _this.decrementProperty('inQueue');
              // save the file name to the image model
              _this.set('isUploading', false);
              return resolve({
                name: name,
                upload_json: r.upload
              });
            },
            error: function error(r) {
              _this.decrementProperty('inQueue');
              _this.set('isUploading', false);
              return reject("Error uploading a file");
            }
          });
        });
      });
    }
  });

  exports['default'] = UploadService;
});