define('frontend2/controllers/buckets/show/upload', ['exports'], function (exports) {
  var BucketsShowUploadController;

  BucketsShowUploadController = Ember.Controller.extend({
    upload: Ember.inject.service(),
    replacedFile: Ember.computed('model.hasTemporaryFile', function () {
      return this.get('model.hasTemporaryFile');
    })
  });

  exports['default'] = BucketsShowUploadController;
});