define('frontend2/components/drm/filter-valid', ['exports'], function (exports) {
  var FilterValidComponent;

  FilterValidComponent = Ember.Component.extend({
    i18n: Ember.inject.service(),
    init: function init() {
      this._super();
      return this.set('choices', [{
        id: 'both',
        label: this.get('i18n').t('filter.expired.all').toString()
      }, {
        id: 'valid',
        label: this.get('i18n').t('filter.expired.valid').toString()
      }, {
        id: 'invalid',
        label: this.get('i18n').t('filter.expired.invalid').toString()
      }]);
    },
    actions: {
      select: function select(val) {
        if (val.id === 'both') {
          return this.set('selected', null);
        } else {
          return this.set('selected', val);
        }
      }
    }
  });

  exports['default'] = FilterValidComponent;
});