define('frontend2/components/administration/users/roles/bucket-roles-table', ['exports', 'ember', 'ember-light-table', 'frontend2/mixins/table-commons'], function (exports, _ember, _emberLightTable, _frontend2MixinsTableCommons) {
  exports['default'] = _ember['default'].Component.extend(_frontend2MixinsTableCommons['default'], {
    store: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    modelName: 'bucket',
    hasSelection: _ember['default'].computed.notEmpty('table.selectedRows'),
    columns: _ember['default'].computed(function () {
      var i18n;
      i18n = this.get('i18n');
      return [{
        label: i18n.t('bucket.name'),
        valuePath: 'name'
      }, {
        label: i18n.t('drm.role'),
        valuePath: 'bucket_user_roles',
        sortable: false,
        cellComponent: 'administration/users/roles/bucket-roles-roles-column'
      }];
    }),
    modelChanged: _ember['default'].observer('model.[]', function () {
      return this.get('table.rows').setEach('userModel', this.get('userModel'));
    }),
    actions: {
      selectAll: function selectAll() {
        return this.get('table.rows').setEach('selected', true);
      },
      deselectAll: function deselectAll() {
        return this.get('table.selectedRows').setEach('selected', false);
      },
      changeRole: function changeRole(role) {
        var store, user;
        store = this.get('store');
        user = this.get('userModel');
        return this.get('table.selectedRows').forEach(function (row) {
          var bucket, existingRoleAssignments, identicalButDeletedAssignment;
          bucket = row.get('content');
          // get all existing assignments, might be more than one as deleted ones are included
          existingRoleAssignments = store.peekAll('bucket-user-role').filter(function (item) {
            return item.get('bucket.id') === bucket.id && item.get('user.id') === user.id;
          });
          // if an identical bucket-user-role exists, but was deleted before, then it needs to be rolled back
          identicalButDeletedAssignment = existingRoleAssignments.findBy('role.id', role.id);
          if (identicalButDeletedAssignment) {
            identicalButDeletedAssignment.rollbackAttributes();
            // delete all other bucket-user-role for the bucket/user
            return existingRoleAssignments.removeObject(identicalButDeletedAssignment).forEach(function (record) {
              return record.deleteRecord();
            });
          } else {
            // in all other cases
            // delete all assignments first
            existingRoleAssignments.forEach(function (record) {
              return record.deleteRecord();
            });
            // create a new assignment
            return store.createRecord('bucket-user-role', {
              bucket: bucket,
              role: role,
              user: user
            });
          }
        });
      },
      removeRole: function removeRole() {
        var store, user;
        store = this.get('store');
        user = this.get('userModel');
        return this.get('table.selectedRows').forEach(function (row) {
          var bucket, existingRoleAssignments;
          bucket = row.get('content');
          // might match more than one as deleted are included
          existingRoleAssignments = store.peekAll('bucket-user-role').filter(function (item) {
            return item.get('user.id') === user.id && item.get('bucket.id') === bucket.id;
          });
          // delete all existing role assignments
          if (existingRoleAssignments) {
            return existingRoleAssignments.forEach(function (existingRoleAssignment) {
              return existingRoleAssignment.deleteRecord();
            });
          }
        });
      }
    }
  });
});