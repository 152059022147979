define('frontend2/components/drm/routes/media-index', ['exports'], function (exports) {
  var MediaIndexComponent;

  MediaIndexComponent = Ember.Component.extend({
    shouldRenderImages: false,
    virtualContainerHeight: 100,
    getPageBodyHeight: function getPageBodyHeight() {
      var standardHeight;
      return regeneratorRuntime.async(function getPageBodyHeight$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            standardHeight = 204;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('showMultiUpload'));

          case 3:
            if (context$1$0.sent) {
              context$1$0.next = 5;
              break;
            }

            standardHeight = 150;

          case 5:
            return context$1$0.abrupt('return', $(window).height() - standardHeight);

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    // 150 #- 204
    updateContainerHeight: function updateContainerHeight() {
      return regeneratorRuntime.async(function updateContainerHeight$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.t0 = this;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.getPageBodyHeight());

          case 3:
            context$1$0.t1 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t0.set.call(context$1$0.t0, 'virtualContainerHeight', context$1$0.t1));

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    didInsertElement: function didInsertElement() {
      return regeneratorRuntime.async(function didInsertElement$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            $(window).on('resize', function () {
              return Ember.run.debounce(_this, _this.updateContainerHeight, 250);
            });
            context$1$0.t0 = this;
            context$1$0.next = 4;
            return regeneratorRuntime.awrap(this.getPageBodyHeight());

          case 4:
            context$1$0.t1 = context$1$0.sent;
            context$1$0.t2 = {
              virtualContainerHeight: context$1$0.t1,
              shouldRenderImages: true
            };
            return context$1$0.abrupt('return', context$1$0.t0.setProperties.call(context$1$0.t0, context$1$0.t2));

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });

  exports['default'] = MediaIndexComponent;
});