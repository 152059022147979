define('frontend2/routes/application-coffee-mixin', ['exports'], function (exports) {
  var ApplicationCoffeeMixin,
      indexOf = [].indexOf;

  ApplicationCoffeeMixin = Ember.Mixin.create({
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    user: Ember.inject.service(),
    upload: Ember.inject.service(),
    overlay: Ember.inject.service(),
    router: Ember.inject.service(),
    authorization: Ember.inject.service(),
    raven: Ember.inject.service(),
    initErrorHandlers: Ember.on('init', function () {
      var _this2 = this;

      Ember.onerror = function (error) {
        return _this2._error(error);
      };
      return Ember.RSVP.on('error', function (error) {
        if (error.name !== "TransitionAborted") {
          return _this2._error(error);
        }
      });
    }),
    initMousewheelFix: Ember.on('init', function () {
      return setInterval(function () {
        var $scrollContainer, curTop;
        $scrollContainer = $('.virtual-each');
        curTop = $scrollContainer.scrollTop();
        $scrollContainer.scrollTop(curTop + 1);
        $scrollContainer.scrollTop(curTop - 1);
        return $scrollContainer.scrollTop(curTop);
      }, 400);
    }),
    _error: function _error(reason, transition) {
      var tagStyle;
      this.get('raven').captureException(reason);
      // 401 unauthorized
      if (reason.statusCode === 401) {
        return this.transitionTo('users.login');
      }
      if (reason.statusCode === 403) {
        return this.transitionTo('users.login');
      }
      tagStyle = 'color: white; background: blue; border-radius: 4px; padding: 2px 6px';
      console.group("%c ⚡️ ERROR ⚡️ ", 'background: red; color: white; font-size: 200%');
      if ("stack" in reason) {
        console.error(reason.stack);
      }
      if ("statusCode" in reason) {
        console.error("%cCode:", tagStyle, reason.statusCode);
        if (typeof reason.error === "string") {
          console.error("%cMessage:", tagStyle, reason.error);
        } else {
          console.error("%cMessage:", tagStyle, reason.error.error);
        }
        if ("args" in reason) {
          console.error("%cArguments:", tagStyle, JSON.stringify(reason.args));
        }
        console.error("%cResponse:", tagStyle);
        console.error(reason.body);
      } else {
        console.error(reason);
      }
      return console.groupEnd();
    },
    model: function model() {
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.get('user.model.id')) {
              context$1$0.next = 3;
              break;
            }

            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('authorization').load());

          case 3:
            return context$1$0.abrupt('return', this.get('store').findAll('option'));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    afterModel: function afterModel(models) {
      var lang;
      lang = this.get('store').peekAll('option').findBy('key', 'lang');
      return this.get('i18n').set('locale', (lang != null ? lang.get('value') : void 0) || 'en');
    },
    onKeyDown: function onKeyDown(e) {
      var bucket, bucketsShowController, filteredUploads, idx, newIdx, ref, upload;
      return true;
      // TODO needs more work, as input fields could be active
      if (this.get('router.currentRouteName') === 'buckets.show.upload' && ((ref = e.key) === 'ArrowLeft' || ref === 'ArrowRight')) {
        upload = this.modelFor('buckets.show.upload');
        bucket = this.modelFor('buckets.show');
        bucketsShowController = this.controllerFor('buckets.show');
        filteredUploads = bucketsShowController.get('filteredUploads');
        idx = filteredUploads.indexOf(upload);
        switch (e.key) {
          case 'ArrowLeft':
            newIdx = idx - 1;
            break;
          case 'ArrowRight':
            newIdx = idx + 1;
        }
        if (newIdx < 0) {
          newIdx = 0;
        }
        if (newIdx >= filteredUploads.get('length') - 1) {
          newIdx = filteredUploads.get('length') - 1;
        }
        return this.transitionTo('buckets.show.upload', bucket.get('id'), filteredUploads.objectAt(newIdx).get('id'));
      }
    },
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      onSocketRecordCreateUpdate: function onSocketRecordCreateUpdate(payload) {
        this.get('store').pushPayload(payload);
      },
      onSocketRecordDeleted: function onSocketRecordDeleted(modelName, id) {
        var record;
        record = this.get('store').peekRecord(modelName, id);
        // untested
        if (record && typeof record.get('dirtyType') === 'undefined') {
          record.unloadRecord();
        }
      },
      createUser: function createUser(user, roleAssignments) {
        return regeneratorRuntime.async(function createUser$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(user.save({
                adapterOptions: {
                  roles: roleAssignments
                }
              }));

            case 2:
              this.transitionTo('administration.users.index');
              return context$1$0.abrupt('return', this.get('notify').success(this.get('i18n').t('users.notifications.create.success').toString()));

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      updateUser: function updateUser(user) {
        var _this3 = this;

        return user.save().then(function () {
          _this3.transitionTo('user.profile');
          return _this3.get('notify').success(_this3.get('i18n').t('user.profile.notifications.success').toString());
        });
      },
      updateOrganization: function updateOrganization(organization) {
        var _this4 = this;

        return organization.save().then(function () {
          _this4.transitionTo('user.profile');
          return _this4.get('nofity').success(_this4.get('i18n').t('user.profile.organization.notifications.success').toString());
        });
      },
      deactivateUser: function deactivateUser(user) {
        var _this;
        _this = this;
        return user.set_active(false).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('deactivate_successful').toString());
        }, function (error) {
          return _this.get('notify').alert(_this.get('i18n').t('deactivate_not_successful').toString());
        });
      },
      activateUser: function activateUser(user) {
        var _this;
        _this = this;
        return user.set_active(true).then(function (data) {
          return _this.get('notify').success(_this.get('i18n').t('reactivate_successful').toString());
        }, function (error) {
          return _this.get('notify').alert(_this.get('i18n').t('reactivate_not_successful').toString());
        });
      },
      deleteUpload: function deleteUpload(upload) {
        var _this5 = this;

        var bucket, yesNo;
        yesNo = confirm(this.get('i18n').t('actions.confirm.delete').toString());
        if (yesNo) {
          bucket = this.modelFor('buckets.show');
          bucket.set('size', bucket.get('size') - upload.get('filesize_total'));
          return upload.destroyRecord().then(function () {
            return _this5.transitionTo('buckets.show', _this5.modelFor('buckets.show'));
          });
        }
      },
      saveUpload: function saveUpload(upload, file) {
        var _this6 = this;

        var bucket, selectedTags, tagUploads;
        bucket = this.modelFor('buckets.show');
        if (!upload.get('isNew')) {
          if (upload.get('hasTemporaryFile')) {
            //unset tags:
            upload.set('hasTemporaryFile', false);
            upload.set('tempId', -1);
          }
          //upload.get('controller').set 'replacedFile', false

          // selectedTags exists
          // check for existing tag-upload
          // compare
          // create new tag-uploads for the ones that don't exists
          tagUploads = upload.get('tag_uploads');
          if (tagUploads.length) {
            upload.get('tag_uploads').forEach(function (tagUpload) {
              return tagUpload.destroyRecord();
            });
          }
          selectedTags = upload.get('selectedTags');
          if (selectedTags.length) {
            upload.get('selectedTags').forEach(function (tag) {
              var rel;
              rel = _this6.get('store').createRecord('tag-upload', {
                tag: tag,
                upload: upload
              });
              return rel.save();
            });
          }
          return upload.save().then(function () {
            var match, uploadFullUrl, uploadTempUrl, xhr;
            // xhr request to invalidate Cache-Control on the image
            xhr = new XMLHttpRequest();
            uploadTempUrl = upload.thumbnail('xs');
            match = uploadTempUrl.match(/(.*)\?/); // url has sth like ?3726
            if (uploadFullUrl) {
              uploadFullUrl = match[1];
              xhr.open("GET", uploadFullUrl, true);
              xhr.setRequestHeader("Cache-Control", "max-age=0");
              xhr.send();
            }
            _this6.get('store').findRecord('bucket', upload.get('bucket.id'));
            // changes can occur in show or search, so routing needs to react
            if (_this6.get('router.currentRouteName') === "buckets.search.upload") {
              return _this6.transitionTo('buckets.search');
            } else {
              return _this6.transitionTo('buckets.show', bucket);
            }
          });
        } else {
          return this.get('upload').saveUpload('media', file, bucket.get('id'), upload.get('getInfosAutomatically')).then(function (data) {
            var savedUpload, serverValuesToKeep;
            // when done push back the payload to the store
            _this6.get('store').pushPayload({
              uploads: [data.upload_json]
            });
            // get the record from the store
            savedUpload = _this6.get('store').peekRecord('upload', data.upload_json.id);
            bucket.set('size', bucket.get('size') + savedUpload.get('filesize_total'));
            // copy all values except the server values that need to be kept
            serverValuesToKeep = ['original_file_name', 'path', 'url', 'content_type', 'download_link', 'width', 'height', 'format', 'depth', 'color', 'res', 'filesize', 'filesize_total', 'bucket', 'tag_uploads'];
            upload.eachAttribute(function (name, meta) {
              if (indexOf.call(serverValuesToKeep, name) < 0) {
                return savedUpload.set(name, upload.get(name));
              }
            });
            // console.log "Attr #{name}", meta
            // console.log "--> #{name}: #{savedUpload.get name} vs. #{upload.get name}"
            upload.eachRelationship(function (name, descriptor) {
              if (indexOf.call(serverValuesToKeep, name) < 0) {
                return savedUpload.set(name, upload.get(name));
              }
            });
            // console.log "Rel #{name}", descriptor
            // console.log "--> #{name}: #{savedUpload.get name + ".id"} #{upload.get name + ".id"}"
            // savedUpload.setProperties
            //   name: upload.get('name')
            //   source: upload.get('source')
            //   license: upload.get('license')
            //   expiry_date: upload.get('expiry_date')
            //   reference_number: upload.get('reference_number')
            // now save it again
            savedUpload.save();
            // each selected tag is added as tag-upload and saved
            selectedTags = upload.get('selectedTags');
            if ('forEach' in selectedTags) {
              selectedTags.forEach(function (tag) {
                var rel;
                rel = _this6.get('store').createRecord('tag-upload', {
                  tag: tag,
                  upload: savedUpload
                });
                return rel.save();
              });
            }
            // delete the client-side created record, after it was created server-side
            upload.deleteRecord();
            return _this6.transitionTo('buckets.show', bucket);
          });
        }
      },
      overlayClose: function overlayClose() {
        return this.get('overlay').close();
      },
      uploadClicked: function uploadClicked(bucket, upload) {
        var bucketModel;
        return regeneratorRuntime.async(function uploadClicked$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              bucketModel = null;
              if (bucket.constructor.modelName === 'bucket') {
                bucketModel = bucket;
              } else {
                bucketModel = bucket.get('content');
              }

              if (!(this.get('router.currentRouteName') === 'buckets.search.index')) {
                context$1$0.next = 6;
                break;
              }

              return context$1$0.abrupt('return', this.transitionTo('buckets.search.upload', upload.get('id')));

            case 6:
              context$1$0.next = 8;
              return regeneratorRuntime.awrap(bucketModel.permitted('canShowUpload'));

            case 8:
              if (!context$1$0.sent) {
                context$1$0.next = 10;
                break;
              }

              return context$1$0.abrupt('return', this.transitionTo('buckets.show.upload', bucketModel, upload.get('id')));

            case 10:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      error: function error(reason, transition) {
        return this._error(reason, transition);
      },
      createBucket: function createBucket(model) {
        var _this7 = this;

        var _bucket, user;
        _bucket = null;
        user = this.get('user');
        return this.get('store').findRecord('user', user.data.id).then(function (userRecord) {
          model.set('owner', userRecord);
          return model.save();
        }).then(function (bucket) {
          return _bucket = bucket;
        }).then(function () {
          return _this7.get('authorization').load();
        }).then(function () {
          return _this7.transitionTo('buckets.show', _bucket);
        });
      },
      updateBucket: function updateBucket(model) {
        var _this8 = this;

        if (model.get('hasDirtyAttributes')) {
          return model.save().then(function (bucket) {
            _this8.get('notify').success(_this8.get('i18n').t('bucket.notifications.rename', {
              bucketName: bucket.get('name')
            }).toString());
            return _this8.transitionTo('buckets.show', bucket);
          });
        }
      },
      deleteBucketGlobal: function deleteBucketGlobal(model) {
        var _this9 = this;

        var yesNo;
        yesNo = confirm(this.get('i18n').t('bucket.actions.delete').toString());
        if (yesNo) {
          return model.destroyRecord().then(function () {
            return _this9.get('notify').success(_this9.get('i18n').t('bucket.notifications.delete', {
              bucketName: model.get('name')
            }).toString());
          });
        }
      },
      removeUserFromBucket: function removeUserFromBucket(bucket, bucket_user) {
        var reallyDelete;
        reallyDelete = confirm(this.get('i18n').t('bucket.action.deleteUser').toString());
        if (reallyDelete) {
          return bucket_user.destroyRecord();
        }
      },
      addUserToBucket: function addUserToBucket(bucket, userEmail) {
        var _this10 = this;

        return this.get('store').query('user', {
          email: userEmail
        }).then(function (result) {
          var bucketUser;
          if (result.get('length') === 0) {
            return bucket.get('errors')._add('user', _this10.get('i18n').t('bucket.notifications.user-does-not-exist').toString());
          } else {
            bucket.get('errors')._remove('user');
            // add user to bucket
            bucketUser = _this10.get('store').createRecord('bucket-user', {
              user: result.get('firstObject'),
              bucket: bucket
            });
            return bucketUser.save().then(function () {
              return _this10.get('notify').success(_this10.get('i18n').t('bucket.notifications.user-added').toString());
            });
          }
        });
      },
      upgrade: function upgrade(user, plan) {
        var checkout;
        checkout = this.get('store').createRecord('checkout', {
          plan: plan,
          user: user
        });
        user.set('plan', plan);
        return checkout.save();
      },
      checkout: function checkout(user, plan, data) {
        var checkout;
        checkout = this.get('store').createRecord('checkout', {
          token: data.id,
          plan: plan,
          user: user
        });
        return checkout.save();
      },
      registerUser: function registerUser(user) {
        var _this11 = this;

        var _this;
        _this = this;
        return user.save().then(function () {
          _this.get('notify').success(_this11.get('i18n').t('user.notifications.registered-successfully').toString());
          return _this11.transitionTo('users.login');
        }, function (reason) {
          var attribute, attribute_errors, invalid_attributes, key, results;
          invalid_attributes = reason.error.invalidAttributes;
          results = [];
          for (attribute in invalid_attributes) {
            attribute_errors = invalid_attributes[attribute];
            results.push((function () {
              var results1;
              results1 = [];
              for (key in attribute_errors) {
                if (attribute_errors.hasOwnProperty(key)) {
                  results1.push(_this.get('notify').alert(attribute_errors[key].message, {
                    closeAfter: 0
                  }));
                } else {
                  results1.push(void 0);
                }
              }
              return results1;
            })());
          }
          return results;
        });
      }
    }
  });

  exports['default'] = ApplicationCoffeeMixin;
});