define('frontend2/services/user', ['exports', 'ember'], function (exports, _ember) {
  var UserService;

  UserService = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    is_admin: false,
    is_editing: false,
    local: false,
    is_not_editing: _ember['default'].computed.not('is_editing'),
    showAnalytics: false,
    data: {},
    model: void 0,
    observeSession: _ember['default'].on('init', _ember['default'].observer('session.isAuthenticated', function () {
      var model, user_data;
      if (this.get('session.isAuthenticated')) {
        user_data = this.get('session.session.content.authenticated.user');
        this.set('role', user_data.role);
        if (user_data.role === "admin") {
          this.set('is_admin', true);
        }
        if (user_data.local === true) {
          this.set('local', true);
        }
        this.set('data', _ember['default'].Object.create(user_data));
        // push the user to the store, get the model and set it as model on this service
        this.get('store').pushPayload({
          users: [user_data]
        });
        model = this.get('store').peekRecord('user', user_data.id);
        return this.set('model', model);
      } else {
        this.set('is_admin', false);
        this.set('role', null);
        return this.set('data', {});
      }
    })),
    displayName: _ember['default'].computed('data.first_name', 'data.last_name', 'data.display_name', function () {
      var name;
      name = this.get('data.display_name');
      if (!name) {
        name = this.get('data.first_name');
      }
      return name;
    })
  });

  exports['default'] = UserService;
});