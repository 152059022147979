define('frontend2/models/plan-feature', ['exports', 'ember-data'], function (exports, _emberData) {
  var PlanFeature;

  PlanFeature = _emberData['default'].Model.extend({
    key: _emberData['default'].attr('string'),
    value: _emberData['default'].attr('string'),
    plan: _emberData['default'].belongsTo('plan', {
      async: true
    })
  });

  exports['default'] = PlanFeature;
});