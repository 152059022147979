define('frontend2/helpers/permitted', ['exports'], function (exports) {
  exports['default'] = Ember.Helper.extend({
    user: Ember.inject.service(),
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    // check for user permissions
    // {{permitted any=(array 'a' 'b' 'c')}}
    // {{permitted all=(array 'a' 'b' 'c')}}
    // {{permitted 'a'}}

    // check for users bucket permissions
    // the bucket parameter needs to be a valid bucket model
    // {{permitted bucket any=(array 'a' 'b' 'c')}}
    // {{permitted bucket all=(array 'a' 'b' 'c')}}
    // {{permitted bucket 'a'}}
    compute: function compute(params, options) {
      var method, methodParams, targetObject;
      // work around lazy injection
      this.get('authorization');
      targetObject = null;
      if ('any' in options) {
        method = 'permittedAny';
        methodParams = options.any;
      } else if ('all' in options) {
        method = 'permittedAll';
        methodParams = options.all;
      } else {
        method = 'permitted';
        methodParams = typeof params[0] === 'string' ? params[0] : params[1];
      }
      targetObject = typeof params[0] === 'object' ? params[0] : this.get('user.model');
      if (targetObject != null) {
        return targetObject[method](methodParams);
      } else {
        return false;
      }
    },
    observeChanges: Ember.observer('authorization.userPermissions.[]', 'authorization.bucketPermissions', function () {
      return this.recompute();
    })
  });
});