define('frontend2/models/bucket-user-role', ['exports', 'ember-data'], function (exports, _emberData) {
  var BucketUserRole;

  BucketUserRole = _emberData['default'].Model.extend({
    bucket: _emberData['default'].belongsTo('bucket', {
      async: true
    }),
    user: _emberData['default'].belongsTo('user', {
      async: true
    }),
    role: _emberData['default'].belongsTo('role', {
      async: true
    })
  });

  exports['default'] = BucketUserRole;
});