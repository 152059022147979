define('frontend2/routes/buckets/show', ['exports', 'frontend2/routes/buckets/search-show-mixin'], function (exports, _frontend2RoutesBucketsSearchShowMixin) {
  var BucketsShowRoute;

  BucketsShowRoute = Ember.Route.extend(_frontend2RoutesBucketsSearchShowMixin['default'], {
    notify: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('bucket', params.bucket_id);
    },
    setupController: function setupController(controller, model) {
      return controller.setProperties({
        model: model,
        filterNotYetShared: model.get('type') === 'store'
      });
    },
    afterModel: function afterModel(bucket, transition) {
      var preloadData;
      return regeneratorRuntime.async(function afterModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(bucket.permitted('canShowUpload'));

          case 2:
            if (context$1$0.sent) {
              context$1$0.next = 4;
              break;
            }

            this.transitionTo('/');

          case 4:
            // preload data
            preloadData = [];
            preloadData.push(this.get('store').query('upload', {
              bucket_id: bucket.get('id')
            }));
            if (bucket.get('type') === 'store') {
              preloadData.push(this.get('store').findAll('publisher'));
              preloadData.push(this.get('store').findAll('pos'));
            }
            return context$1$0.abrupt('return', Ember.RSVP.Promise.all(preloadData));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model;
        this._super();
        this.controller.set('q', '');
        // clear selection of moving away from the buckets.show
        // or child routes
        if (!transition.targetName.match(/buckets\.show.*/)) {
          model = this.currentModel;
          return model.get('uploads').setEach('selected', false);
        }
      }
    }
  });

  exports['default'] = BucketsShowRoute;
});