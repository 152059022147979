define('frontend2/services/authorization', ['exports'], function (exports) {
  exports['default'] = Ember.Service.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    userPermissions: [],
    bucketPermissions: {},
    detectSessionChanges: Ember.on('init', Ember.observer('session.isAuthenticated', function () {
      return this.load();
    })),
    load: function load() {
      return regeneratorRuntime.async(function load$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.t0 = this;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(Ember.RSVP.hash({
              roles: this.get('store').findAll('role'),
              rolePermissions: this.get('store').findAll('role-permission'),
              permissions: this.get('store').findAll('permission'),
              userRole: this.get('store').queryRecord('user-role', {
                user_id: this.get('user.model.id')
              }),
              bucketUserRoles: this.get('store').query('bucket-user-role', {
                user_id: this.get('user.model.id')
              }),
              buckets: this.get('store').findAll('bucket')
            }));

          case 3:
            context$1$0.t1 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t0.initialize.call(context$1$0.t0, context$1$0.t1));

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    initialize: function initialize(data) {
      var bucketPermissions, bucketUserRoles, permissions, permissionsById, permissionsByRole, rolePermissions, roles, rolesById, userPermissions, userRole;
      roles = data.roles;
      rolePermissions = data.rolePermissions;
      permissions = data.permissions;
      userRole = data.userRole;
      bucketUserRoles = data.bucketUserRoles;

      rolesById = roles.reduce(function (map, item) {
        map[item.get('id')] = item;
        return map;
      }, {});
      permissionsById = permissions.reduce(function (map, item) {
        map[item.get('id')] = item;
        return map;
      }, {});
      permissionsByRole = rolePermissions.reduce(function (map, item) {
        var permissionId, roleId;
        roleId = item.get('role.id');
        permissionId = item.get('permission.id');
        if (!(roleId in map)) {
          map[roleId] = [];
        }
        map[roleId].push(permissionsById[permissionId].get('name'));
        return map;
      }, {});
      userPermissions = [];
      if (userRole != null) {
        userPermissions = permissionsByRole[userRole.get('role.id')];
      }
      bucketPermissions = bucketUserRoles.reduce(function (map, item) {
        var bucketId, roleId;
        bucketId = item.get('bucket.id');
        roleId = item.get('role.id');
        map[bucketId] = permissionsByRole[roleId];
        return map;
      }, {});
      this.set('userPermissions', userPermissions);
      return this.set('bucketPermissions', bucketPermissions);
    },
    getGlobalPermissions: function getGlobalPermissions() {
      return this.get('userPermissions');
    },
    getBucketPermissions: function getBucketPermissions(bucketId) {
      var bucketPermissions;
      bucketPermissions = this.get('bucketPermissions');
      if (!(bucketId in bucketPermissions)) {
        return [];
      }
      return bucketPermissions[bucketId];
    }
  });
});