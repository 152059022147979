define('frontend2/routes/index', ['exports', 'ember'], function (exports, _ember) {
  ;
  var IndexRoute;

  IndexRoute = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    user: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        return this.transitionTo('buckets.index');
      } else {
        return this.transitionTo('users.login');
      }
      return true;
    },
    model: function model() {
      return this.get('store').findAll('user');
    }
  });

  exports['default'] = IndexRoute;
  ;
});