define('frontend2/routes/users/join', ['exports'], function (exports) {
  var UsersJoinRoute;

  UsersJoinRoute = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('user');
    }
  });

  exports['default'] = UsersJoinRoute;
});