define('frontend2/controllers/application', ['exports'], function (exports) {
  var ApplicationController;

  ApplicationController = Ember.Controller.extend({
    user: Ember.inject.service(),
    overlay: Ember.inject.service(),
    change_body_margin: (function () {
      if (this.get('user.is_admin')) {
        return $('body').addClass('admin');
      } else {
        return $('body').removeClass('admin');
      }
    }).observes('user.is_admin')
  });

  exports['default'] = ApplicationController;
  ;
});