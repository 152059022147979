define('frontend2/routes/buckets/show/publish', ['exports'], function (exports) {
  exports['default'] = Ember.Route.extend({
    overlay: Ember.inject.service(),
    model: function model() {
      return new Ember.RSVP.hash({
        imagesizes: this.get('store').findAll('imagesize')
      });
    },
    setupController: function setupController(controller, models) {
      return controller.setProperties(models);
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('buckets.show', _this.modelFor('buckets.show'));
      });
    },
    deactivate: function deactivate() {
      return this.get('overlay').hide();
    }
  });

  // actions:
  //   formSubmitted: ->
  //     @send 'shared', @modelFor(@routeName).get('email')
  //     @transitionTo 'buckets.show', @modelFor('buckets.show')
  //     return false

  // cancel: ->
  //   @transitionTo 'buckets.show', @modelFor('buckets.show')
});