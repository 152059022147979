define('frontend2/components/drm/stripe-checkout', ['exports'], function (exports) {
  var StripeCheckoutComponent;

  StripeCheckoutComponent = Ember.Component.extend({
    user: null,
    plan: null,
    handle: null,
    checkout: null,
    init: function init() {
      this._super();
      return this.setupStripeHandler();
    },
    setupStripeHandler: function setupStripeHandler() {
      var _this = this;

      if (!this.get('handler')) {
        return this.set('handler', StripeCheckout.configure({
          key: 'pk_test_P68AHH2FAkjZHpGf0zTcHHFd',
          image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
          locale: 'auto',
          token: function token(data) {
            return _this.get('checkout')(_this.get('user'), _this.get('plan'), data);
          }
        }));
      }
    },
    actions: {
      pay: function pay() {
        return this.get('handler').open({
          name: 'Asset Hoshi',
          currency: 'eur',
          amount: this.get('plan.price'),
          description: 'Ein Paketupgrade zu ' + this.get('plan.name'),
          panelLabel: 'Direkt upgraden',
          email: this.get('user.email')
        });
      }
    }
  });

  exports['default'] = StripeCheckoutComponent;
});