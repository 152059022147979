define('frontend2/components/ext/calendar-week', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    weeks: [],
    week: 1,
    init: function init() {
      this._super();
      return this.set('weeks', (function () {
        var results = [];
        for (var j = 1; j <= 52; j++) {
          results.push(j);
        }
        return results;
      }).apply(this).map(function (i) {
        return {
          value: i,
          label: 'KW ' + i
        };
      }));
    }
  });
});