define('frontend2/transitions/grow', ['exports'], function (exports) {
  var grow;

  grow = function () {
    var _this = this;

    return new Ember.RSVP.Promise(function (resolve) {
      _this.newElement.css('visibility', 'visible');
      return _this.newElement.velocity('slideDown', {
        duration: 350,
        complete: function complete() {
          return resolve();
        }
      });
    });
  };

  exports['default'] = grow;
});