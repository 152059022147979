define('frontend2/components/drm/upload-view', ['exports'], function (exports) {
  var UploadViewComponent;

  UploadViewComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    user: Ember.inject.service(),
    classNameBindings: [':upload-view__list-item', 'upload.isExpired', 'clickable'],
    isEditing: false,
    selected: false,
    // load new thumbnail if the url changes
    imageStyle: Ember.computed('upload.url', function () {
      var styleArray, urlStr;
      urlStr = this.get('upload').thumbnail('xs');
      styleArray = ['background-image: url(' + urlStr + ')'];
      return Ember.String.htmlSafe(styleArray.join(";"));
    }),
    dragStart: function dragStart(evt) {
      this.set('showFolders', true);
      return evt.dataTransfer.setData('uploadId', this.get('upload.id'));
    },
    dragEnd: function dragEnd() {
      return this.set('showFolders', false);
    },
    click: function click(e) {
      if (window.navigator.platform.match('Mac') && e.metaKey || e.ctrlKey) {
        return this.get('upload').toggleProperty('selected', true);
      } else {
        return this.get('uploadClicked')(this.get('upload'));
      }
    },
    actions: {
      startEditing: function startEditing() {
        return this.set('isEditing', true);
      },
      updateUpload: function updateUpload(upload) {
        var _this = this;

        var tagsToUpdate;
        upload.save();
        tagsToUpdate = upload.get('selectedTags');
        tagsToUpdate.forEach(function (tag) {
          return upload.get('tag_uploads').forEach(function (tagUpload) {
            if (tag === tagUpload.get('tag')) {
              return tagsToUpdate.removeObject(tag);
            }
          });
        });
        tagsToUpdate.forEach(function (tag) {
          var rel;
          rel = _this.get('store').createRecord('tag-upload', {
            tag: tag,
            upload: upload
          });
          return rel.save();
        });
        return this.set('isEditing', false);
      }
    }
  });

  exports['default'] = UploadViewComponent;
});