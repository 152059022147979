define('frontend2/routes/user/profile/change-password', ['exports'], function (exports) {
  var ChangePasswordRoute;

  ChangePasswordRoute = Ember.Route.extend({
    overlay: Ember.inject.service(),
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),
    model: function model() {
      var userProfileModels;
      userProfileModels = this.modelFor('user.profile');
      return userProfileModels.model;
    },
    activate: function activate() {
      var _this2 = this;

      return this.get('overlay').show(function () {
        return _this2.transitionTo('user.profile');
      });
    },
    deactivate: function deactivate() {
      return this.get('overlay').hide();
    },
    actions: {
      change_password: function change_password() {
        var _this, passwords, user;
        user = this.modelFor(this.routeName);
        _this = this;
        passwords = this.get('controller.model').getProperties('password_old', 'password_new', 'password_newConfirmation');
        return user.change_password(passwords).then(function (data) {
          _this.get('notify').success(_this.get('i18n').t('password_update_successful').toString());
          return _this.transitionTo('user.profile');
        }, function (error) {
          var attribute, attribute_errors, invalid_attributes, key, results;
          invalid_attributes = error.error.invalidAttributes;
          results = [];
          for (attribute in invalid_attributes) {
            attribute_errors = invalid_attributes[attribute];
            results.push((function () {
              var results1;
              results1 = [];
              for (key in attribute_errors) {
                if (attribute_errors.hasOwnProperty(key)) {
                  results1.push(_this.get('notify').alert(attribute_errors[key].message, {
                    closeAfter: 0
                  }));
                } else {
                  results1.push(void 0);
                }
              }
              return results1;
            })());
          }
          return results;
        });
      }
    }
  });

  exports['default'] = ChangePasswordRoute;
});