define('frontend2/instance-initializers/user-and-session', ['exports'], function (exports) {
  exports.initialize = initialize;
  // injects the user and session into all controllers
  var _init;

  _init = function (application) {
    // container = application.container
    // registry = application.registry
    application.inject('controller', 'user', 'service:user');
    application.inject('route', 'user', 'service:user');
    application.inject('component', 'user', 'service:user');
    return application.inject('controller', 'session', 'session:main');
  };

  function initialize(application) {
    _init(application);
  }

  ;

  exports['default'] = { name: 'user_and_session', initialize: initialize };
  ;
});