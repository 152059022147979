define('frontend2/routes/buckets/show/settings', ['exports'], function (exports) {
  var BucketsShowSettingsRoute;

  BucketsShowSettingsRoute = Ember.Route.extend({
    overlay: Ember.inject.service(),
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('buckets.show', _this.modelFor('buckets.show'));
      });
    },
    deactivate: function deactivate() {
      return this.get('overlay').hide();
    }
  });

  exports['default'] = BucketsShowSettingsRoute;
});