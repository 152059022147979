define('frontend2/routes/buckets/search/share', ['exports', 'frontend2/routes/buckets/search-show-share-mixin', 'frontend2/routes/buckets/overlay-routing-mixin-factory'], function (exports, _frontend2RoutesBucketsSearchShowShareMixin, _frontend2RoutesBucketsOverlayRoutingMixinFactory) {
  var OverlayRoutingMixin;

  OverlayRoutingMixin = (0, _frontend2RoutesBucketsOverlayRoutingMixinFactory['default'])('buckets.search');

  exports['default'] = Ember.Route.extend(OverlayRoutingMixin, _frontend2RoutesBucketsSearchShowShareMixin['default'], {
    actions: {
      formSubmitted: function formSubmitted() {
        this.send('shared', this.modelFor(this.routeName));
        this.transitionTo('buckets.search');
        return false;
      }
    }
  });
});