define('frontend2/authenticators/local', ['exports', 'frontend2/authenticators/base'], function (exports, _frontend2AuthenticatorsBase) {
  ;
  var LocalAuthenticator;

  LocalAuthenticator = _frontend2AuthenticatorsBase['default'].extend({
    auth_endpoint: 'local-login',
    authenticate: function authenticate(options) {
      var _this;
      _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var credentials;
        credentials = JSON.stringify({
          email: options.email,
          password: options.password
        });
        return Ember.$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: _this.host + _this.auth_endpoint,
          type: 'POST',
          data: credentials,
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve(response);
          });
        }, function (xhr, status, error) {
          var response_text;
          response_text = JSON.parse(xhr.responseText);
          Ember.run(function () {
            reject(response_text.error);
          });
        });
      });
    }
  });

  exports['default'] = LocalAuthenticator;
  ;
});