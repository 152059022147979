define('frontend2/serializers/application', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  var get;

  get = Ember.get;

  exports['default'] = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var adapter;
      adapter = this.store.adapterFor(primaryModelClass.modelName);
      adapter._listenToSocket(primaryModelClass.modelName);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});