define('frontend2/components/buckets/search-input', ['exports', 'frontend2/config/environment', 'ember'], function (exports, _frontend2ConfigEnvironment, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    bucket_id: void 0,
    classNameBindings: [':search--search-field', 'empty'],
    q: '',
    searchResults: _ember['default'].A([]),
    suggests: _ember['default'].A([]),
    suggest: '',
    shouldDisplayResults: _ember['default'].computed.gt('q.length', 1),
    resultsChanged: _ember['default'].observer('searchResults', function () {
      return this.sendAction('searchChanged', this.get('searchResults'), this.get('q'));
    }),
    _init: _ember['default'].on('init', function () {
      var config;
      return config = this.get('container').lookupFactory('config:environment');
    }),
    empty: _ember['default'].computed('q', function () {
      return this.get('q').trim() === '';
    }),
    qMap: {
      'verlag:': 'publisher:',
      'autor:': 'author:',
      'werbefläche:': 'pos:',
      'titel:': 'title:',
      'heftnummer:': 'issue_number:',
      'kw:': 'start_week:',
      'status:neu': 'shared:0',
      'status:geteilt': 'shared:1',
      'top:ja': 'is_top:1',
      'top:nein': 'is_top:0',
      'verband:ja': 'is_association_booking:1',
      'verband:nein': 'is_association_booking:0',
      'premium:ja': 'is_association_premium_booking:1',
      'premium:nein': 'is_association_premium_booking:0',
      'bereich:presse': 'division:Presse',
      'bereich:buch': 'division:Buch',
      'bereich:': 'upload_common_area:',
      'kategorie:': 'upload_common_category:',
      'thema:': 'upload_common_category:',
      'warengruppe:': 'upload_common_commodity_group:',
      'typ:pos': 'upload_type:1',
      'typ:allgemein': 'upload_type:2',
      'datum:': 'date:',
      'tag:': 'tags:',
      "\\s+und\\s+": ' AND ',
      "\\s+oder\\s+": ' OR '
    },
    // observes the input field for q, starts search after 300ms delay after
    // the last change
    qChanged: _ember['default'].observer('q', function () {
      if (this.get('q.length') > 1) {
        return _ember['default'].run.debounce(this, this._startSearch, 1000);
      } else {
        return this.set('searchResults', _ember['default'].A([]));
      }
    }),
    _didInsertElement: _ember['default'].on('didInsertElement', function () {
      if (true === this.get('focus')) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          var _this = this;

          return setTimeout(function () {
            return _this.$('.search--search-input').focus();
          }, 100);
        });
      }
      if (this.get('q').trim().length > 0) {
        return this.send('startSearch');
      }
    }),
    //#############################################################################
    actions: {
      //#############################################################################
      startSearch: function startSearch() {
        if (this.get('q').trim() === '') {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            var _this2 = this;

            return setTimeout(function () {
              return _this2.$('.search--search-input').focus();
            }, 100);
          });
        }
        return this._startSearch();
      }
    },
    //#############################################################################
    // Private API
    //#############################################################################
    _startSearch: function _startSearch() {
      var _this3 = this;

      return this._execSearch().then(function (searchResults) {
        console.log("*************************************************************");
        console.log(searchResults);
        console.log("vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
        _this3.set('suggests', searchResults.suggest);
        if (searchResults.hits.total > 0) {
          return _this3.set('searchResults', searchResults.hits.hits);
        } else {
          return _this3.set('searchResults', _ember['default'].A([]));
        }
      });
    },
    _execSearch: function _execSearch() {
      var _this4 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var q, url, urlParams;
        q = _this4._processQ();
        urlParams = _this4.get('bucket_id') ? encodeURI(q) + '/' + _this4.get('bucket_id') : encodeURI(q);
        url = '' + (_frontend2ConfigEnvironment['default'].APP.protocol || '') + (_frontend2ConfigEnvironment['default'].APP.host || '') + '/api/search/' + urlParams;
        console.log("ES-Search URL", url);
        return _ember['default'].$.ajax({
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          url: url,
          type: 'POST',
          contentType: 'application/json'
        }).then(function (response) {
          return resolve(response);
        }, function (xhr, status, error) {
          var response_text;
          response_text = JSON.parse(xhr.responseText);
          return reject(response_text.error);
        });
      });
    },
    _processQ: function _processQ() {
      var k, q, ref, v;
      q = this.get('q');
      ref = this.get('qMap');
      for (k in ref) {
        v = ref[k];
        if (k.indexOf(':') < 0) {
          q = q.replace(RegExp('(.*)' + k + '(.*)', "i"), '$1' + v + '$2');
        } else {
          q = q.replace(RegExp('(.*)' + k + '(.*)', "i"), '$1' + v + '$2');
        }
      }
      return q;
    }
  });
});