define("frontend2/components/drm/image-upload", ["exports"], function (exports) {
  var ImageUploadComponent;

  ImageUploadComponent = Ember.Component.extend({
    user: Ember.inject.service(),
    store: Ember.inject.service(),
    upload: Ember.inject.service()
  });

  exports["default"] = ImageUploadComponent;
});