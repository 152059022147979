define('frontend2/components/drm/application-view', ['exports', 'ember'], function (exports, _ember) {
  var ApplicationViewComponent;

  ApplicationViewComponent = _ember['default'].Component.extend({
    setup: (function () {
      var _this = this;

      // loading logic, remove spinner icon, add check icon, fade out
      $('#loading .fa-spinner').removeClass('fa-spinner').removeClass('fa-pulse').addClass('fa-check');
      setTimeout(function () {
        return $('#loading').velocity('fadeOut', {
          duration: 500
        });
      }, 500);
      // keyboard keys
      return $(document).on('keydown', function (e) {
        var route;
        route = _ember['default'].getOwner(_this).lookup('route:application');
        return route.onKeyDown.call(route, e);
      });
    }).on('didInsertElement')
  });

  exports['default'] = ApplicationViewComponent;
});