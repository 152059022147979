define('frontend2/transitions/shrink', ['exports'], function (exports) {
  var shrink;

  shrink = function () {
    var _this = this;

    return new Ember.RSVP.Promise(function (resolve) {
      _this.oldElement.css('visibility', 'visible');
      return _this.oldElement.velocity('slideUp', {
        duration: 350,
        complete: function complete() {
          return resolve();
        }
      });
    });
  };

  exports['default'] = shrink;
});