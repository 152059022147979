define('frontend2/helpers/format-file-size', ['exports', 'ember'], function (exports, _ember) {
  var FormatFileSizeHelper, formatFileSize, humanFileSize;

  humanFileSize = function (size) {
    var i;
    if (size === 0) {
      return "0MB";
    }
    i = Math.floor(Math.log(size) / Math.log(1000));
    return (size / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  };

  // This function receives the params `params, hash`
  exports.formatFileSize = formatFileSize = function (params) {
    return humanFileSize(params[0]);
  };

  FormatFileSizeHelper = _ember['default'].Helper.helper(formatFileSize);

  exports.formatFileSize = formatFileSize;
  exports['default'] = FormatFileSizeHelper;
});