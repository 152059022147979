define('frontend2/models/checkout', ['exports', 'ember-data'], function (exports, _emberData) {
  var Checkout;

  Checkout = _emberData['default'].Model.extend({
    token: _emberData['default'].attr('string'),
    plan: _emberData['default'].belongsTo('plan'),
    user: _emberData['default'].belongsTo('user')
  });

  exports['default'] = Checkout;
});