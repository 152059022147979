define('frontend2/routes/buckets/index/create', ['exports'], function (exports) {
  var BucketsIndexCreateRoute;

  BucketsIndexCreateRoute = Ember.Route.extend({
    overlay: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('bucket');
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('buckets.index');
      });
    },
    deactivate: function deactivate() {
      this.get('overlay').hide();
      if (this.currentModel.get('isNew')) {
        return this.currentModel.deleteRecord();
      }
    }
  });

  exports['default'] = BucketsIndexCreateRoute;
});