define('frontend2/services/page-body', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    pageBodyHeight: 100,
    getPageBodyHeight: function getPageBodyHeight() {
      return $(window).height() - 150;
    },
    updateContainerHeight: function updateContainerHeight() {
      return regeneratorRuntime.async(function updateContainerHeight$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.t0 = this;
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.getPageBodyHeight());

          case 3:
            context$1$0.t1 = context$1$0.sent;
            return context$1$0.abrupt('return', context$1$0.t0.set.call(context$1$0.t0, 'pageBodyHeight', context$1$0.t1));

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    init: function init() {
      var _this = this;

      $(window).on('resize', function () {
        return _ember['default'].run.debounce(_this, _this.updateContainerHeight, 250);
      });
      return this.updateContainerHeight();
    }
  });
});