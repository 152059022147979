define('frontend2/components/ext/year-select', ['exports'], function (exports) {
  exports['default'] = Ember.Component.extend({
    years: [],
    init: function init() {
      var currentTime, currentYear, nextYear;
      this._super();
      currentTime = moment();
      currentYear = currentTime.format('YYYY');
      nextYear = currentTime.add(1, 'year').format('YYYY');
      return this.set('years', [currentYear, nextYear]);
    }
  });
});