define('frontend2/controllers/users/join', ['exports', 'ember', 'ember-validations', 'frontend2/validators/patterns'], function (exports, _ember, _emberValidations, _frontend2ValidatorsPatterns) {
  ;
  ;
  ;
  var UsersJoinController;

  UsersJoinController = _ember['default'].Controller.extend(_emberValidations['default'], {
    is_not_valid: _ember['default'].computed.not('isValid'),
    i18n: _ember['default'].inject.service(),
    set_validation_messages: (function () {
      this.get('validations')['model.display_name']['presence']['message'] = this.get('i18n').t("validation_messages.display_name.required").toString();
      this.get('validations')['model.email']['presence']['message'] = this.get('i18n').t("validation_messages.email.required").toString();
      this.get('validations')['model.email']['format']['message'] = this.get('i18n').t("validation_messages.email.invalid_format").toString();
      this.get('validations')['model.password']['presence']['message'] = this.get('i18n').t("validation_messages.password.required").toString();
      this.get('validations')['model.password']['length']['message'] = this.get('i18n').t("validation_messages.password.too_short", 6).toString();
      return this.get('validations')['model.password']['confirmation']['message'] = this.get('i18n').t("validation_messages.password.confirmation").toString();
    }).on('init'),
    validations: {
      'model.display_name': {
        presence: {
          message: ''
        }
      },
      'model.email': {
        presence: {
          message: ''
        },
        format: {
          'with': _frontend2ValidatorsPatterns['default'].email,
          message: ''
        }
      },
      'model.password': {
        presence: {
          message: ''
        },
        length: {
          minimum: 6,
          messages: {
            tooShort: ''
          }
        },
        confirmation: {
          message: ''
        }
      }
    }
  });

  exports['default'] = UsersJoinController;
  ;
});