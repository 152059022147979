define('frontend2/components/buckets/show/upload/download-imagesizes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    init: function init() {
      var imagesizes;
      return regeneratorRuntime.async(function init$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this._super();
            this.set('imagesizes', new Em.A());
            imagesizes = this.get('store').peekAll('imagesize');

            if (!(imagesizes.get('length') === 0)) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.get('store').findAll('imagesize'));

          case 6:
            imagesizes = context$1$0.sent;

          case 7:
            return context$1$0.abrupt('return', this.set('imagesizes', imagesizes));

          case 8:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });
});