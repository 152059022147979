define('frontend2/controllers/user/profile/change-password', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
  ;
  ;
  var UserChangePasswordController;

  UserChangePasswordController = _ember['default'].Controller.extend(_emberValidations['default'], {
    i18n: _ember['default'].inject.service(),
    is_not_valid: _ember['default'].computed.not('isValid'),
    set_validation_messages: (function () {
      this.get('validations')['model.password_new']['presence']['message'] = this.get('i18n').t("validation_messages.password_new.required").toString();
      this.get('validations')['model.password_new']['length']['messages']['tooShort'] = this.get('i18n').t("validation_messages.password.too_short", {
        count: 6
      }).toString();
      this.get('validations')['model.password_new']['confirmation']['message'] = this.get('i18n').t("validation_messages.password.confirmation").toString();
      return this.get('validations')['model.password_old']['presence']['message'] = this.get('i18n').t("validation_messages.password_old.required").toString();
    }).on('init'),
    validations: {
      'model.password_new': {
        presence: {
          message: ''
        },
        length: {
          minimum: 6,
          messages: {
            tooShort: ''
          }
        },
        confirmation: {
          message: ''
        }
      },
      'model.password_old': {
        presence: {
          message: ''
        }
      }
    }
  });

  exports['default'] = UserChangePasswordController;
  ;
});