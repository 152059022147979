define('frontend2/routes/administration', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  ;
  var AdministrationRoute;

  AdministrationRoute = Ember.Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    user: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('user.model').permitted('canManageMasterData'));

          case 2:
            if (context$1$0.sent) {
              context$1$0.next = 4;
              break;
            }

            return context$1$0.abrupt('return', this.transitionTo('index'));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    }
  });

  exports['default'] = AdministrationRoute;
});