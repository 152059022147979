define('frontend2/routes/administration/mixins/edit-route-factory', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  exports['default'] = function (modelName) {
    return Ember.Mixin.create({
      templateName: 'administration/maintenance-view-edit',
      overlay: Ember.inject.service(),
      model: function model(params) {
        return this.get('store').find(modelName, params.id);
      },
      setupController: function setupController(controller, model) {
        return controller.setProperties({
          model: model,
          modelName: modelName
        });
      },
      activate: function activate() {
        var _this = this;

        return this.get('overlay').show(function () {
          _this.transitionTo('administration.' + (0, _emberInflector.pluralize)(modelName) + '.index');
          if (_this.modelFor(_this.routeName).get('hasDirtyAttributes')) {
            _this.modelFor(_this.routeName).rollbackAttributes();
          }
          return _this.get('overlay').hide();
        });
      },
      actions: {
        submit: function submit() {
          return this.send('update', this.currentModel);
        }
      }
    });
  };

  ;
});