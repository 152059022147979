define('frontend2/routes/buckets/search/share-with-publisher', ['exports', 'frontend2/routes/buckets/share-with-publisher-mixin', 'frontend2/routes/buckets/overlay-routing-mixin-factory'], function (exports, _frontend2RoutesBucketsShareWithPublisherMixin, _frontend2RoutesBucketsOverlayRoutingMixinFactory) {
  var OverlayRoutingMixin;

  OverlayRoutingMixin = (0, _frontend2RoutesBucketsOverlayRoutingMixinFactory['default'])('buckets.search');

  exports['default'] = Ember.Route.extend(_frontend2RoutesBucketsShareWithPublisherMixin['default'], OverlayRoutingMixin, {
    actions: {
      formSubmitted: function formSubmitted() {
        this.send('shareWithPublisher', this.modelFor(this.routeName));
        this.transitionTo('buckets.search');
        return false;
      }
    }
  });
});