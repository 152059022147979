define('frontend2/components/drm/modal-dialog', ['exports'], function (exports) {
  var ModalDialogComponent;

  ModalDialogComponent = Ember.Component.extend({
    _getModal: function _getModal() {
      return this.$().find('.modal');
    },
    didInsertElement: function didInsertElement() {
      return this._getModal().modal({
        backdrop: 'static'
      });
    },
    actions: {
      close: function close() {
        return this._getModal().modal('hide');
      }
    }
  });

  exports['default'] = ModalDialogComponent;
});