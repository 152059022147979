define('frontend2/controllers/media', ['exports'], function (exports) {
  var MediaController;

  MediaController = Ember.Controller.extend({
    user: Ember.inject.service(),
    tags: null,
    filterTags: [],
    filterName: '',
    filterSource: '',
    filterLicense: '',
    filterValid: null,
    showFilter: false,
    numberOfActiveFilters: Ember.computed('filterTags.[]', 'filterName', 'filterSource', 'filterLicense', 'filterValid', function () {
      var field, i, len, num, ref;
      num = 0;
      ref = ['filterTags', 'filterName', 'filterSource', 'filterLicense', 'filterValid'];
      for (i = 0, len = ref.length; i < len; i++) {
        field = ref[i];
        if (Ember.isPresent(this.get(field))) {
          num += 1;
        }
      }
      return num;
    }),
    init: function init() {
      var _this = this;

      this.get('store').findAll('tag').then(function (tags) {
        return _this.set('tags', tags);
      });
      return this._super();
    },
    filteredUploads: Ember.computed('model.uploads.[]', 'filterTags.[]', 'filterName', 'filterSource', 'filterValid', 'filterLicense', function () {
      var _this2 = this;

      var filterLicense, filterName, filterSource, filterValid, neededHits, now, uploads;
      filterName = this.get('filterName').toLowerCase();
      filterSource = this.get('filterSource').toLowerCase();
      filterLicense = this.get('filterLicense').toLowerCase();
      filterValid = this.get('filterValid');
      now = moment();
      uploads = this.get('model.uploads').toArray().reverse().filter(function (upload) {
        var filterBool, uploadExpiryDate;
        filterBool = true;
        filterBool = filterBool && upload.get('name').toLowerCase().indexOf(filterName) >= 0;
        filterBool = filterBool && upload.get('source').toLowerCase().indexOf(filterSource) >= 0;
        filterBool = filterBool && upload.get('license').toLowerCase().indexOf(filterLicense) >= 0;
        if (filterValid != null) {
          uploadExpiryDate = moment(upload.get('expiry_date'));
          if (filterValid.id === 'valid') {
            filterBool = filterBool && !upload.get('isExpired'); //uploadExpiryDate.isAfter(now)
          } else if (filterValid.id === 'invalid') {
              filterBool = filterBool && upload.get('isExpired'); //now.isAfter(uploadExpiryDate)
            }
        }
        return filterBool;
      });
      neededHits = this.get('filterTags').length;
      return uploads.filter(function (upload) {
        var hits;
        hits = 0;
        _this2.get('filterTags').forEach(function (filterTag) {
          return upload.get('tag_uploads').forEach(function (tagUpload) {
            if (tagUpload.get('tag.name') === filterTag.get('name')) {
              return hits += 1;
            }
          });
        });
        if (hits === neededHits) {
          return true;
        } else {
          return false;
        }
      });
    }),
    actions: {
      filter: function filter(upload) {
        console.log("FILTER", upload.get('tags'), this.get('filterTags'));
        return true;
      },
      resetFilter: function resetFilter() {
        var field, i, len, ref;
        ref = ['filterName', 'filterSource', 'filterLicense'];
        for (i = 0, len = ref.length; i < len; i++) {
          field = ref[i];
          this.set(field, '');
        }
        this.set('filterValid', null);
        return this.set('filterTags', []);
      }
    }
  });

  exports['default'] = MediaController;
});