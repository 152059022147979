define('frontend2/models/organization', ['exports', 'ember-data'], function (exports, _emberData) {
  var Organization;

  Organization = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    plan: _emberData['default'].belongsTo('plan'),
    users: _emberData['default'].hasMany('users'),
    buckets_size: _emberData['default'].attr('number'),
    quotaInPercent: Ember.computed('buckets_size', 'plan.size', function () {
      if (this.get('plan.size')) {
        return this.get('buckets_size') / this.get('plan.size');
      } else {
        return 0;
      }
    })
  });

  exports['default'] = Organization;
});