define('frontend2/routes/administration/mixins/index-route', ['exports', 'frontend2/mixins/table-commons-route'], function (exports, _frontend2MixinsTableCommonsRoute) {
  exports['default'] = Ember.Mixin.create(_frontend2MixinsTableCommonsRoute['default'], {
    templateName: 'administration/maintenance-view-index',
    i18n: Ember.inject.service(),
    overlay: Ember.inject.service(),
    model: function model() {
      return new Em.A([]);
    },
    actions: {
      create: function create(genericModel) {
        return regeneratorRuntime.async(function create$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(genericModel.save());

            case 2:
              this.modelFor(this.routeName).pushObject(genericModel);
              return context$1$0.abrupt('return', this.get('overlay').close());

            case 4:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      'delete': function _delete(genericModel) {
        var yesNo;
        return regeneratorRuntime.async(function _delete$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              yesNo = confirm(this.get('i18n').t('actions.confirm.delete'));

              if (!yesNo) {
                context$1$0.next = 6;
                break;
              }

              this.modelFor(this.routeName).removeObject(genericModel);
              context$1$0.next = 5;
              return regeneratorRuntime.awrap(genericModel.destroyRecord());

            case 5:
              return context$1$0.abrupt('return', context$1$0.sent);

            case 6:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      update: function update(genericModel) {
        return regeneratorRuntime.async(function update$(context$1$0) {
          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              context$1$0.next = 2;
              return regeneratorRuntime.awrap(genericModel.save());

            case 2:
              return context$1$0.abrupt('return', this.get('overlay').close());

            case 3:
            case 'end':
              return context$1$0.stop();
          }
        }, null, this);
      },
      cancel: function cancel() {
        return this.get('overlay').close();
      }
    }
  });
});