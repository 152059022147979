define('frontend2/components/drm/routes/user-profile', ['exports'], function (exports) {
  var UserProfileComponent;

  UserProfileComponent = Ember.Component.extend({
    currentPlan: null,
    actions: {
      setCurrentPlan: function setCurrentPlan(plan) {
        return this.set('currentPlan', plan);
      }
    },
    setMaxHeightForEls: function setMaxHeightForEls($els) {
      var maxHeight;
      $els.css('height', 'auto');
      maxHeight = 0;
      $els.each(function (i, el) {
        return maxHeight = Math.max(maxHeight, $(el).height());
      });
      return $els.height(maxHeight);
    },
    fooChanged: Ember.observer('plans.@each.plan_features', function () {
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        return $('.card-deck .card:first-child .list-group-item--same-height').each(function (i, el) {
          var key, maxHeight;
          key = $(el).data('key');
          return maxHeight = _this.setMaxHeightForEls($('.list-group-item--' + key));
        });
      });
    })
  });

  exports['default'] = UserProfileComponent;
});