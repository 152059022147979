define('frontend2/helpers/thumbnail', ['exports', 'ember'], function (exports, _ember) {
  var ThumbnailHelper, thumbnail;

  // This function receives the params `params, hash`
  //params[0]: upload model
  //params[1]: string - thumbnail type
  //params[2]: boolean - show replaced file (can be empty)
  exports.thumbnail = thumbnail = function (params) {
    if (params[2] && params[2] === true) {
      //load temporary thumbnail of the replaced file
      return params[0].thumbnail(params[1], params[2]);
    } else {
      //load thumbnail
      return params[0].thumbnail(params[1]);
    }
  };

  ThumbnailHelper = _ember['default'].Helper.helper(thumbnail);

  exports.thumbnail = thumbnail;
  exports['default'] = ThumbnailHelper;
});