define('frontend2/mixins/table-commons-route', ['exports', 'ember', 'ember-light-table', 'ember-concurrency'], function (exports, _ember, _emberLightTable, _emberConcurrency) {
  var Mixin, computed, inject, isEmpty;

  inject = _ember['default'].inject;
  isEmpty = _ember['default'].isEmpty;
  computed = _ember['default'].computed;
  Mixin = _ember['default'].Mixin;
  exports['default'] = Mixin.create({
    store: inject.service(),
    page: 0,
    limit: 10,
    dir: 'asc',
    sort: 'id',
    isLoading: computed.oneWay('fetchRecords.isRunning'),
    canLoadMore: true,
    enableSync: true,
    modelName: null,
    meta: null,
    columns: null,
    table: null,
    setupController: function setupController(controller, model) {
      var sortColumn, table;
      // create the table, use columns, the model and enableSync
      table = new _emberLightTable['default'](this.get('columns'), model, {
        enableSync: this.get('enableSync')
      });
      sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }
      return controller.setProperties({
        'table': table,
        'modelName': this.get('modelName')
      });
    },
    fetchRecords: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var queryParams, records;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            queryParams = this._buildQueryParams();
            context$1$0.next = 3;
            return this.get('store').query(this.get('modelName'), queryParams);

          case 3:
            records = context$1$0.sent;

            this.modelFor(this.routeName).pushObjects(records.toArray());
            this.set('meta', records.get('meta'));
            return context$1$0.abrupt('return', this.set('canLoadMore', !isEmpty(records)));

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })).restartable(),
    _buildQueryParams: function _buildQueryParams() {
      return {
        sort: this.get('sort') + ' ' + this.get('dir'),
        skip: (Number(this.get('page')) - 1) * Number(this.get('limit')),
        limit: this.get('limit')
      };
    },
    _obReload: _ember['default'].observer('reload', function () {
      return this._reset();
    }),
    _reset: function _reset() {
      var _this = this;

      return setTimeout(function () {
        _this.setProperties({
          canLoadMore: true,
          page: 0
        });
        return _this.modelFor(_this.routeName).clear();
      }, 2000);
    },
    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.incrementProperty('page');
          return this.get('fetchRecords').perform();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            canLoadMore: true,
            page: 0
          });
          return this.modelFor(this.routeName).clear();
        }
      }
    }
  });
});