define('frontend2/routes/administration/issue-numbers/index', ['exports', 'ember', 'frontend2/routes/administration/mixins/index-route'], function (exports, _ember, _frontend2RoutesAdministrationMixinsIndexRoute) {
  exports['default'] = _ember['default'].Route.extend(_frontend2RoutesAdministrationMixinsIndexRoute['default'], {
    modelName: 'issue-number',
    columns: _ember['default'].computed(function () {
      var i18n;
      i18n = this.get('i18n');
      return [{
        label: i18n.t('name'),
        valuePath: 'name'
      }, {
        label: '',
        sortable: false,
        cellComponent: 'administration/maintenance-table-actions-column',
        cellClassNames: ['table__actions'],
        width: '100px'
      }];
    })
  });
});