define('frontend2/models/publisher', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    division: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    receiver: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    contact: _emberData['default'].belongsTo('contact'),
    // national_distributor: DS.belongsTo 'national-distributor'
    save: function save(options) {
      var contact;
      return regeneratorRuntime.async(function save$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.get('contact'));

          case 2:
            contact = context$1$0.sent;
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(contact.save());

          case 5:
            context$1$0.next = 7;
            return regeneratorRuntime.awrap(this._internalModel.save(options));

          case 7:
            context$1$0.next = 9;
            return regeneratorRuntime.awrap(contact.save());

          case 9:
            return context$1$0.abrupt('return', context$1$0.sent);

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },
    toString: function toString() {
      return this.get('name');
    }
  });
});

// save contact

// save publisher

// save contact again to set publisher_id