define('frontend2/locales/en/translations', ['exports'], function (exports) {
  var translations;

  translations = {
    validation_messages: {
      length: {
        tooShort: 'The field needs to contain at least {{count}} characters.'
      },
      presence: 'A value is required for this field.',
      first_name: {
        required: 'Please enter your first name.'
      },
      last_name: {
        required: 'Please enter your last name.'
      },
      display_name: {
        required: 'Please enter your display name.'
      },
      email: {
        required: 'An email address is required.',
        invalid_format: 'The format of your email address is not valid.'
      },
      password: {
        required: 'Please enter your password.',
        too_short: 'The password needs to be at least {{count}} characters long.',
        confirmation: 'The entered passwords do not match.'
      },
      password_old: {
        required: 'Please enter your current password'
      },
      password_new: {
        required: ' Please enter your new password'
      }
    },
    update_successful: 'Successfully updated!',
    update_not_successful: 'The changes could not be saved.',
    deactivate_successful: 'The user was deactivated.',
    deactivate_not_successful: 'The user could not be deactivated.',
    reactivate_successful: 'The user was reactivated.',
    reactivate_not_successful: 'The user could not be reactivated.',
    password_update_successful: 'The password was successfully changed.',
    successfully_saved: 'Successfully saved!',
    user_role: {
      admin: 'Admin',
      customer: 'Member'
    },
    drm: {
      firstName: 'First name',
      lastName: 'Last name',
      displayName: 'Display name',
      company: 'Company',
      companySlug: 'Company URL',
      email: 'Email',
      role: 'Role',
      password: 'Password',
      passwordOld: 'Current password',
      passwordNew: 'New password',
      passwordNewConfirmation: 'Password confirmation.',
      passwordConfirmation: 'Password confirmation',
      action: {
        one: 'Action',
        other: 'Actions'
      },
      upload: {
        name: 'Name',
        source: 'Source',
        license: 'License',
        expiry_date: 'Expiry date',
        reference_number: 'Reference number',
        description: 'Description',
        autoInfo: 'Retrieve description and tags automatically'
      },
      bucket: {
        name: 'Name'
      },
      notifications: {
        success: 'Success',
        warning: 'Warning',
        information: 'Information',
        error: 'Error'
      },
      input: {
        placeholder: {
          pleaseChoose: 'Please choose ...'
        }
      },
      actions: {
        upload: 'upload',
        update: 'update',
        deactivate: 'Deactivate',
        activate: 'Activate',
        registerNow: 'register',
        login: 'login',
        create: 'create',
        submit: 'send',
        resetPassword: 'Request a new password'
      },
      models: {
        organization: {
          modelName: 'Organization'
        },
        imagesize: {
          name: 'Name',
          width: 'Width',
          height: 'Height',
          crop: 'Crop'
        },
        user: {
          active: 'Active?'
        }
      },
      selects: {
        noMatchesMessage: 'No results'
      }
    },
    'login.page-headline': 'Login',
    'login.problems': 'If you are unable to login please contact us <a href="http://www.calliesundschewe.de/en/contact/" target="_blank">here</a>.',
    'login.forgot-password': 'Forgot password',
    'user.create.page-headline': 'Create user',
    'actions.cancel': 'cancel',
    'actions.close': 'close',
    'users.index.page-headline': 'User list',
    'bucket.create.page-headline': 'Create an image database',
    'bucket.edit.page-headline': 'Database settings',
    'bucket.basic-data': 'General data',
    'actions.save': 'save',
    'upload.page-headline': 'Upload image',
    'upload.expiry-date': 'Expiry date',
    'upload.expired': 'Expired',
    'buckets.index.page-headline': 'Image databases',
    'bucket.name': 'Database name',
    'bucket.images': 'Images',
    'bucket.size': 'Size',
    'buckets.index.no-buckets-yet': 'No databases have been created yet.',
    'bucket.imagesShown': '{{count}} shown',
    'bucket.no-images-yet': 'No images have been uploaded yet.',
    'bucket.no-images-found': 'No search results',
    'search.typeToSearch': 'Search',
    'filter.tags': 'Tags',
    'filter.name': 'Name',
    'filter.source': 'Source',
    'filter.license': 'License',
    'filter.expired': 'Validity filter',
    'filter.namePlaceholder': 'name filter',
    'filter.tagPlaceholder': 'tag filter',
    'filter.sourcePlaceholder': 'source filter',
    'filter.licensePlaceholder': 'license filter',
    'filter.expiredPlaceholder': 'validity filter',
    'filter.actions.resetFilter': 'reset filter',
    'password.change.page-headline': 'Change your password',
    'organization.edit.page-headline': 'Edit organization',
    'profile.edit.page-headline': 'Edit user profile',
    'profile.page-headline': 'Profile',
    'user.profile': 'User profile',
    'actions.edit': 'edit',
    'user.actions.change-password': 'change password',
    'user.actions.editOrganization': 'edit organization',
    'user.password-reset.page-headline': 'Reset password',
    'user.password-reset.intro': 'Please enter your email address. We will send you an email with a new password.',
    'user.password-reset.actions.back-to-login': 'login',
    'general.email': 'Email',
    'general.password': 'Password',
    'bucket.owner': 'Owner',
    'filter.headline': 'Filter',
    'tags.placeholder': 'Choose tags',
    'tags.label': 'Tags',
    'upload.uploadHelp': 'Click here to choose an image or drag &amp; drop here.',
    'upload.replaceDropzone': '<strong>Replace file</strong><br>Click to open the file picker or use drag &amp; drop to upload another image',
    'general.none': 'none',
    'actions.display': 'show',
    'actions.delete': 'delete',
    'actions.label': 'Actions',
    'general.of': 'of',
    'actions.confirm.delete': 'Are you sure you want to delete this?',
    'bucket.actions.delete': 'Are you sure you want to delete the image database? All images will also be deleted and cannot be restored.',
    'bucket.action.deleteUser': 'Are you sure you want to delete this user?',
    'actions.confirm.changesAreLost': 'Are you sure you want to close this? Any unsaved changes will be lost.',
    'password-reset.email-does-not-exist': 'This email address is not known by our database.',
    'password-reset.success-check-emails': 'Please check your inbox. You should have received a new password. Please also check your spam folder.',
    'users.notifications.create.success': 'The user was successfully created.',
    'user.profile.notifications.success': 'Your profile was updated.',
    'user.profile.organization.notifications.success': 'The organization was updated successfully.',
    'bucket.notifications.rename': 'Bucket successfully renamed to {{bucketName}}.',
    'bucket.notifications.delete': 'Bucket {{bucketName}} deleted successfully.',
    'bucket.notifications.user-does-not-exist': 'This user does not exist.',
    'bucket.notifications.user-added': 'User added',
    'user.notifications.registered-successfully': 'The registration was successful, you can login now!',
    'filter.expired.all': 'all',
    'filter.expired.valid': 'valid',
    'filter.expired.invalid': 'expired',
    'upload.multiupload.help': '<strong>Upload</strong><br>Click to open the file picker or drag & drop to upload files.',
    'upload.multiupload.files-in-progress': 'files are uploaded...',
    'upload.actions.move': 'Move',
    'upload.confirmations.move': 'Should the asset really be moved?',
    'user.roles.page-headline': 'Manage user roles',
    'user.roles.uploader.short': 'Upload',
    'user.roles.uploader.long': 'Can upload single or multiple files',
    'user.roles.viewer.short': 'Viewer',
    'user.roles.viewer.long': 'Can view images',
    'user.roles.editor.short': 'Editor',
    'user.roles.editor.long': 'Can edit image data',
    'user.roles.sharer.short': 'Sharer',
    'user.roles.sharer.long': 'Can share data',
    'user.roles.admin.short': 'Admin',
    'user.roles.admin.long': 'Can change settings',
    'user.roles.downloader.short': 'Downloader',
    'user.roles.downloader.long': 'Can download images',
    'user.roles.creator.short': 'Creator',
    'user.roles.creator.long': 'Can create databases',
    'user.roles.deleter.short': 'Delete',
    'user.roles.deleter.long': 'Can delete databases and images',
    'user.roles.basicUser.short': 'Basic user',
    'user.roles.basicUser.long': 'Display databases and download images.',
    'user.roles.powerUser.short': 'Power user',
    'user.roles.powerUser.long': 'Like basic user, additionally create databases, upload and edit images.',
    'user.roles.global-roles-legend': 'Global user roles',
    'user.permissions.canManageUsers.short': 'User:Admin',
    'user.permissions.canShowBucket.short': 'Bucket:Show',
    'user.permissions.canEditBucket.short': 'Bucket:Edit',
    'user.permissions.canShowUpload.short': 'Upload:Show',
    'user.permissions.canEditUpload.short': 'Upload:Edit',
    'user.permissions.canMoveUpload.short': 'Upload:Move',
    'user.permissions.canCreateBucket.short': 'Bucket:Create',
    'user.permissions.canUploadSingle.short': 'Upload:Upload',
    'user.permissions.canUploadMulti.short': 'Upload:Multiupload',
    'user.permissions.canDownload.short': 'Upload:Download',
    'user.permissions.canDeleteBucket.short': 'Bucket:Delete',
    'user.permissions.canDeleteUpload.short': 'Upload:Delete',
    'user.permissions.canManageImagesizes.short': 'Images:Manage',
    'user.permissions.canShareBucket.short': 'Upload:Share',
    'user.permissions.canPublishBucket.short': 'Upload:Publish',
    'user.rights': 'Roles',
    'user.permissions': 'Included permissions',
    'user.roles.bucket-roles': 'Database roles',
    'tables.footer.entries': '{{count}} entries',
    'roles.actions.remove': 'Remove role',
    'user.your-profile': 'Your Profil',
    'administration.user-management': 'User Management',
    'administration.image-sizes': 'Images Sizes',
    'home': 'Home',
    'name': 'Name',
    'imagesizes.create.page-headline': 'Create Imagesize',
    'imagesizes.original': 'Original file',
    'upload.download.too-small': 'The image resolution is too small, it will be upscaled.',
    'actions.download': 'Download',
    'buckets.search.page-headline': 'Fulltext Search',
    'actions.share': 'Share',
    'actions.publish': 'Publish',
    'actions.unpublish': 'Unpublish',
    'actions.getUrl': 'Get public URL',
    'actions.delete.tooltip': '<strong>Delete</strong><br>Cannot be undone.',
    'actions.download.tooltip': '<strong>Download</strong><br>Download the images in different sizes.',
    'actions.publish.tooltip': '<strong>Publish</strong><br>Enable public versions of this image.',
    'actions.unpublish.tooltip': '<strong>Unpublish</strong><br>Disable public versions of this image.',
    'actions.getUrl.tooltip': '<strong>Get URL</strong><br>Get public URLs for different image sizes.',
    'actions.filter.tooltip': '<strong>Filter</strong><br>Define filter criteria.',
    'actions.upload.tooltip': '<strong>Upload</strong><br>Open the single file upload dialog.',
    'actions.more.tooltip': '<strong>More actions</strong>',
    'actions.share.tooltip': '<strong>Share</strong><br>Share image(s) via email.',
    'actions.openBucketSettings.tooltip': '<strong>Database settings</strong><br>Opens the database settings dialog.',
    'actions.home.tooltip': '<strong>Home</strong>',
    'actions.search.tooltip': '<strong>Search</strong>',
    'actions.settings.tooltip': '<strong>Global Settings</strong>',
    'actions.profile.tooltip': '<strong>Manage your profile</strong>',
    'actions.manageUsers.tooltip': '<strong>Manage Users, Roles and Permissions.</strong>',
    'actions.manageImagesizes.tooltip': '<strong>Manage Imagesizes</strong>',
    'actions.logout.tooltip': '<strong>Logout</strong>',
    'upload.published.tooltip': '<strong>Public</strong>',
    'actions.createBucket.tooltip': '<strong>Create a new image database</strong>',
    'actions.editProfile.tooltip': '<strong>Edit profile</strong>',
    'actions.changePassword.tooltip': '<strong>Change password</strong>',
    'actions.createUsers.tooltip': '<strong>Create a new user</strong>',
    'actions.deselectAll.tooltip': '<strong>Deselect all</strong>',
    'actions.selectAll.tooltip': '<strong>Select all</strong>',
    'administration.users.roles.help': 'Toggle the selection by using the button on the left side. Use <code>Ctrl</code> + <code>click</code> on Windows or <code>⌘</code> + <code>click</code> on Mac to select multiple entries.',
    'actions.addImagesize.tooltip': 'Add a new image size',
    'change-to': 'Change to'
  };

  exports['default'] = translations;
});