define('frontend2/components/drm/upload-tags', ['exports'], function (exports) {
  var UploadTagsComponent;

  UploadTagsComponent = Ember.Component.extend({
    classNames: ['form-group', 'upload__tags'],
    store: Ember.inject.service(),
    tags: [],
    msg: null,
    keyDown: function keyDown(e) {
      if (e.which === 13) {
        return this.send('createTag', this.get('newTag'));
      }
    },
    init: function init() {
      var _this = this;

      this.get('store').findAll('tag').then(function (tags) {
        return _this.set('tags', tags);
      });
      return this._super();
    },
    actions: {
      createTagOnEnter: function createTagOnEnter(dropdown, e) {
        var i, len, newTag, newTagModel, newTagNames;
        if (e.which === 13 && Ember.isEmpty(dropdown.highlighted)) {
          e.preventDefault();
          newTagNames = e.target.value.split(",").map(function (newTag) {
            return newTag.trim();
          });
          for (i = 0, len = newTagNames.length; i < len; i++) {
            newTag = newTagNames[i];
            if (newTag === '' || Ember.isEmpty(newTag)) {
              continue;
            }
            newTagModel = this.get('store').createRecord('tag', {
              name: newTag
            });
            newTagModel.save();
            this.get('selectedTags').pushObject(newTagModel);
            dropdown.actions.close();
          }
          return false;
        }
      }
    }
  });

  exports['default'] = UploadTagsComponent;
});