define('frontend2/helpers/classify', ['exports', 'ember-cli-string-helpers/helpers/classify'], function (exports, _emberCliStringHelpersHelpersClassify) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersClassify['default'];
    }
  });
  Object.defineProperty(exports, 'classify', {
    enumerable: true,
    get: function get() {
      return _emberCliStringHelpersHelpersClassify.classify;
    }
  });
});