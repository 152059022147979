define('frontend2/models/pos', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    division: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    toString: function toString() {
      return this.get('name');
    }
  });
});