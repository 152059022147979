define('frontend2/initializers/authentication', ['exports', 'frontend2/authenticators/local'], function (exports, _frontend2AuthenticatorsLocal) {
  ;
  var AuthenticationInitializer;

  AuthenticationInitializer = {
    name: 'authentication',
    before: 'ember-simple-auth',
    after: 'torii',
    initialize: function initialize(container) {
      return container.register('ember-simple-auth-authenticator:local', _frontend2AuthenticatorsLocal['default']);
    }
  };

  exports['default'] = AuthenticationInitializer;
  ;
});