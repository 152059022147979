define('frontend2/components/buckets/buckets-table', ['exports', 'ember', 'ember-light-table', 'frontend2/mixins/table-commons'], function (exports, _ember, _emberLightTable, _frontend2MixinsTableCommons) {
  exports['default'] = _ember['default'].Component.extend(_frontend2MixinsTableCommons['default'], {
    pageBody: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    modelName: 'bucket',
    bucketsFilter: -1,
    sort: 'name',
    obBucketsFilter: _ember['default'].observer('bucketsFilter', function () {
      var bf;
      bf = this.get('bucketsFilter');
      if (bf === -1) {
        this.set('where', null);
      } else {
        this.set('where', {
          type: bf
        });
      }
      return this._reset(100);
    }),
    columns: _ember['default'].computed(function () {
      var i18n;
      i18n = this.get('i18n');
      return [{
        label: i18n.t('bucket.name'),
        valuePath: 'name',
        cellComponent: 'buckets/buckets-table-name-column'
      }, {
        label: i18n.t('bucket.images'),
        valuePath: 'count',
        sortable: false
      }, {
        label: i18n.t('bucket.size'),
        valuePath: 'size',
        cellComponent: 'buckets/buckets-table-size-column'
      }, {
        label: '',
        sortable: false,
        cellComponent: 'buckets/buckets-table-actions-column',
        cellClassNames: ['table__actions'],
        width: '100px'
      }];
    })
  });
});