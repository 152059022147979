define('frontend2/components/page-top-inner', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'page__top__inner',
    flexHeight: 520,
    willDestroyElement: function willDestroyElement() {
      return clearInterval(this.get('interval'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      return this.set('interval', setInterval(function () {
        var sum;
        sum = 0;
        _this.$().find('.fixed-height').each(function (i, el) {
          return sum += $(el).innerHeight();
        });
        return _this.set('flexHeight', $(window).height() - sum - 210);
      }, 250));
    }
  });
});