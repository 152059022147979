define('frontend2/routes/buckets/overlay-routing-mixin-factory', ['exports'], function (exports) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = function (routeName) {
    for (var _len = arguments.length, transitionParams = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      transitionParams[_key - 1] = arguments[_key];
    }

    return Ember.Mixin.create({
      overlay: Ember.inject.service(),
      _getTransitionParams: function _getTransitionParams() {
        var _this = this;

        var processedParams;
        processedParams = transitionParams.map(function (p) {
          if (typeof p === 'function') {
            return p.call(_this);
          } else {
            return p;
          }
        });
        if (!processedParams) {
          processedParams = [];
        }
        processedParams.unshift(routeName);
        return processedParams;
      },
      activate: function activate() {
        var _this2 = this;

        return this.get('overlay').show(function () {
          var _transitionTo;

          return (_transitionTo = _this2.transitionTo).call.apply(_transitionTo, [_this2].concat(_toConsumableArray(_this2._getTransitionParams())));
        });
      },
      deactivate: function deactivate() {
        return this.get('overlay').hide();
      },
      actions: {
        cancel: function cancel() {
          var _transitionTo2;

          return (_transitionTo2 = this.transitionTo).call.apply(_transitionTo2, [this].concat(_toConsumableArray(this._getTransitionParams())));
        }
      }
    });
  };

  ;
});