define('frontend2/controllers/user/profile/edit-organization', ['exports', 'ember-validations'], function (exports, _emberValidations) {
  ;
  var EditOrganizationController, ORGANIZATION_MIN_CHARS;

  ORGANIZATION_MIN_CHARS = 2;

  EditOrganizationController = Ember.Controller.extend(_emberValidations['default'], {
    i18n: Ember.inject.service(),
    is_not_valid: Ember.computed.not('isValid'),
    set_validation_messages: (function () {
      this.get('validations')['model.name']['presence']['message'] = this.get('i18n').t("validation_messages.presence").toString();
      return this.get('validations')['model.name']['length']['messages']['tooShort'] = this.get('i18n').t("validation_messages.length.tooShort", {
        count: ORGANIZATION_MIN_CHARS
      }).toString();
    }).on('init'),
    validations: {
      'model.name': {
        presence: {
          message: ''
        },
        length: {
          minimum: ORGANIZATION_MIN_CHARS,
          messages: {
            tooShort: ''
          }
        }
      }
    }
  });

  exports['default'] = EditOrganizationController;
});