define('frontend2/services/overlay', ['exports'], function (exports) {
  var OverlayService;

  OverlayService = Ember.Service.extend({
    showOverlay: false,
    callback: null,
    show: function show(callback) {
      this.set('showOverlay', true);
      this.set('callback', callback);
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return $('.overlay').velocity('fadeIn', {
          duration: 350
        });
      });
    },
    hide: function hide() {
      this.set('showOverlay', false);
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return $('.overlay').velocity('fadeOut', {
          duration: 350
        });
      });
    },
    close: function close() {
      var ok;
      if (this.get('callback')) {
        ok = this.get('callback')();
        if (ok) {
          this.hide();
          return this.set('callback', null);
        }
      } else {
        return this.hide();
      }
    }
  });

  exports['default'] = OverlayService;
});