define('frontend2/transitions', ['exports'], function (exports) {
  var transitions_map;

  transitions_map = function () {
    this.transition(this.fromRoute('administration.users.index.index'), this.toRoute('administration.users.index.create'), this.use('crossFade'), this.reverse('crossFade'));
    this.transition(this.fromRoute('administration.users.index.index'), this.toRoute('administration.users.index.roles'), this.use('crossFade'), this.reverse('crossFade'));
    this.transition(this.fromRoute('buckets.index.index'), this.toRoute('buckets.index.create'), this.use('crossFade'), this.reverse('crossFade'));
    this.transition(this.fromRoute('buckets.show.index'), this.toRoute('buckets.show.settings'), this.use('crossFade'), this.reverse('crossFade'));
    this.transition(this.fromRoute('buckets.show.index'), this.toRoute('buckets.show.upload'), this.use('crossFade'), this.reverse('crossFade'));
    this.transition(this.outletName('page-top'), this.use('crossFade'), this.reverse('crossFade'));
    return this.transition(this.use('crossFade'));
  };

  exports['default'] = transitions_map;
  ;
});