define('frontend2/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  var Tag;

  Tag = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    tag_uploads: _emberData['default'].hasMany('tag-upload', {
      async: true
    })
  });

  exports['default'] = Tag;
});