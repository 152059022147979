define('frontend2/app', ['exports', 'ember', 'frontend2/resolver', 'ember-load-initializers', 'frontend2/config/environment', 'frontend2/models/custom-inflector-rules'], function (exports, _ember, _frontend2Resolver, _emberLoadInitializers, _frontend2ConfigEnvironment, _frontend2ModelsCustomInflectorRules) {

  // dont' load images on drag and drop
  window.addEventListener("dragover", function (e) {
    e = e || event;
    e.preventDefault();
  }, false);

  window.addEventListener("drop", function (e) {
    e = e || event;
    e.preventDefault();
  }, false);

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _frontend2ConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _frontend2ConfigEnvironment['default'].podModulePrefix,
    Resolver: _frontend2Resolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _frontend2ConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});