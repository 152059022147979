define('frontend2/models/role-permission', ['exports', 'ember-data'], function (exports, _emberData) {
  var RolePermission;

  RolePermission = _emberData['default'].Model.extend({
    role: _emberData['default'].belongsTo('role', {
      async: true
    }),
    permission: _emberData['default'].belongsTo('permission', {
      async: true
    })
  });

  exports['default'] = RolePermission;
});