define('frontend2/components/drm/media-upload', ['exports', 'vibrant', 'frontend2/templates/components/drm/media-upload'], function (exports, _vibrant, _frontend2TemplatesComponentsDrmMediaUpload) {
  var MediaUploadComponent, UPLOAD_NAME_MIN_CHARS;

  UPLOAD_NAME_MIN_CHARS = 2;

  MediaUploadComponent = Ember.Component.extend({
    layout: _frontend2TemplatesComponentsDrmMediaUpload['default'],
    user: Ember.inject.service(),
    i18n: Ember.inject.service(),
    /* INTERFACE */
    upload: null,
    file: null,
    formSubmitted: null,
    _obValidations: Ember.computed('upload.isBook', 'upload.isPoSUpload', function () {
      return this.buildValidators();
    }),
    customIsValid: Ember.computed('isValid', 'file', function () {
      // debugger
      if (this.get('upload.isNew')) {
        return !Ember.isEmpty(this.get('file')) && this.get('isValid');
      } else {
        return this.get('isValid');
      }
    }),
    createColorThiefPalette: Ember.on('didInsertElement', function () {
      var _this = this;

      var img;
      // FIXME remove #schmittundhahn
      // The code after return false threw an error on upload
      return false;
      img = new Image();
      img.src = $('.upload__image img').attr('src');
      return img.onload = function () {
        var colorThief, swatches, vibrant;
        colorThief = new ColorThief();
        _this.set('colorMain', colorThief.getColor(img));
        _this.set('colorPalette', colorThief.getPalette(img, 8));
        vibrant = new _vibrant['default'](img);
        swatches = vibrant.swatches();
        if ("Vibrant" in swatches && swatches.Vibrant) {
          return _this.set('colorVibrant', swatches.Vibrant.rgb);
        }
      };
    }),
    actions: {
      fileLoaded: function fileLoaded(file) {
        var user;
        user = this.get('user.model');
        // TODO fix the quota stuff #quota #feature-plans
        // if user.isQuotaReached file
        //   alert "Upload not possible, quota reached"
        // else
        //   alert "Upload possible"
        return this.set('file', file);
      },
      formSubmitted: function formSubmitted() {
        var _this2 = this;

        return this.validate().then(function () {
          return _this2.get('formSubmitted')(_this2.get('upload'), _this2.get('file'));
        }, function (error) {
          return console.log("formSubmitted: invalid");
        });
      }
    }
  });

  exports['default'] = MediaUploadComponent;
});