define('frontend2/locales/de/translations', ['exports'], function (exports) {
  var translations;

  translations = {
    validation_messages: {
      length: {
        tooShort: 'Das Feld muss mindestens {{count}} Zeichen lang sein.'
      },
      presence: 'Bitte tätigen Sie eine Eingabe.',
      first_name: {
        required: 'Bitte geben Sie Ihren Vornamen ein.'
      },
      last_name: {
        required: 'Bitte geben Sie Ihren Nachnamen ein.'
      },
      display_name: {
        required: 'Bitte geben Sie einen Anzeigenamen ein.'
      },
      email: {
        required: 'Bitte geben Sie eine E-Mail Adresse ein.',
        invalid_format: 'Ihre E-Mail ist ungültig.'
      },
      password: {
        required: 'Bitte geben Sie ein Passwort ein.',
        too_short: 'Das Passwort muss mindestens {{count}} Buchstaben beinhalten.',
        confirmation: 'Die eingegebenen Passwörter stimmen nicht überein.'
      },
      password_old: {
        required: 'Bitte geben Sie Ihr altes Passwort ein.'
      },
      password_new: {
        required: ' Bitte geben Sie Ihr neues Passwort ein.'
      }
    },
    update_successful: 'Erfolgreich geändert!',
    update_not_successful: 'Die Änderungen konnten nicht gespeichert werden. Bitte den Support kontaktieren.',
    deactivate_successful: 'Der Benutzer wurde deaktiviert.',
    deactivate_not_successful: 'Der Benutzer konnte nicht deaktiviert werden. Bitte den Support kontaktieren.',
    reactivate_successful: 'Der Benutzer wurde reaktiviert.',
    reactivate_not_successful: 'Der Benutzer konnte nicht reaktiviert werden. Bitte den Support kontaktieren.',
    password_update_successful: 'Passwort erfolgreich geändert.',
    comment_created_successful: 'Der Kommentar wurde erfolgreich erstellt.',
    comment_created_successful_by_other: 'Ein anderer Benutzer hat einen Kommentar erstellt.',
    successfully_saved: 'Erfolgreich gespeichert!',
    user_role: {
      admin: 'Admin',
      customer: 'Mitglied'
    },
    drm: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      displayName: 'Name',
      company: 'Firma',
      companySlug: 'Firmen-URL',
      email: 'E-Mail',
      role: 'Rolle',
      password: 'Passwort',
      passwordOld: 'Bisheriges Passwort',
      passwordNew: 'Neues Passwort',
      passwordNewConfirmation: 'Passwort-Wdh.',
      passwordConfirmation: 'Passwort-Wdh.',
      action: {
        one: 'Aktion',
        other: 'Aktionen'
      },
      upload: {
        name: 'Name (Bitte einen sprechenden Namen eintragen)',
        source: 'Quelle',
        license: 'Lizenz',
        expiry_date: 'Ablaufdatum',
        reference_number: 'Referenznummer',
        description: 'Beschreibung',
        autoInfo: 'Tags und Beschreibung automatisch ermitteln'
      },
      bucket: {
        name: 'Bezeichnung'
      },
      notifications: {
        success: 'Erfolg',
        warning: 'Warnung',
        information: 'Information',
        error: 'Fehler'
      },
      input: {
        placeholder: {
          pleaseChoose: 'Bitte wählen ...'
        }
      },
      actions: {
        upload: 'hochladen',
        update: 'aktualisieren',
        deactivate: 'Deaktivieren',
        activate: 'Aktivieren',
        registerNow: 'registrieren',
        login: 'anmelden',
        create: 'erstellen',
        submit: 'senden',
        resetPassword: 'Neues Passwort anfordern'
      },
      models: {
        organization: {
          modelName: 'Organisation'
        },
        imagesize: {
          name: 'Bezeichnung',
          width: 'Breite',
          height: 'Höhe',
          crop: 'Zurechtschneiden'
        },
        user: {
          active: 'Aktiv?'
        }
      },
      selects: {
        noMatchesMessage: 'Keine Ergebnisse'
      }
    },
    'login.page-headline': 'Anmeldung',
    'login.problems': 'Falls Sie Probleme mit dem Einloggen haben <a href="mailto:support@triggerco.de" target="_blank">melden Sie sich bei uns</a>.',
    'login.forgot-password': 'Passwort vergessen',
    'user.create.page-headline': 'Benutzer erstellen',
    'actions.cancel': 'abbrechen',
    'actions.close': 'schließen',
    'users.index.page-headline': 'Benutzerliste',
    'bucket.create.page-headline': 'Datenbank erstellen',
    'bucket.edit.page-headline': 'Datenbankeinstellungen',
    'bucket.basic-data': 'Basisdaten',
    'actions.save': 'speichern',
    'actions.give-feedback': 'Feedback senden',
    'upload.page-headline': 'Bild hochladen',
    'upload.expiry-date': 'Ablaufdatum',
    'upload.expired': 'abgelaufen',
    'buckets.index.page-headline': 'Bilderdatenbanken',
    'bucket.name': 'Datenbankname',
    'bucket.images': 'Bilder',
    'bucket.size': 'Größe',
    'buckets.index.no-buckets-yet': 'Noch keine Bilddatenbanken erstellt.',
    'bucket.imagesShown': 'davon {{count}} angezeigt',
    'bucket.no-images-yet': 'Keine Bilder zur Anzeige vorhanden',
    'bucket.no-images-found': 'Keine Suchergebnisse',
    'search.typeToSearch': 'Suche',
    'filter.tags': 'Tag-Filter',
    'filter.name': 'Suchwort Name',
    'filter.source': 'Suchwort Quelle',
    'filter.license': 'Suchwort Lizenz',
    'filter.expired': 'Gültigkeitsfilter',
    'filter.namePlaceholder': 'Suchwort Name',
    'filter.tagPlaceholder': 'Suchwort Tag',
    'filter.sourcePlaceholder': 'Suchwort Quelle',
    'filter.licensePlaceholder': 'Suchwort Lizenz',
    'filter.expiredPlaceholder': 'Filter nach Gültigkeit',
    'filter.actions.resetFilter': 'Filter zurücksetzen',
    'password.change.page-headline': 'Passwort ändern',
    'organization.edit.page-headline': 'Organisation bearbeiten',
    'profile.edit.page-headline': 'Benutzerprofil bearbeiten',
    'profile.page-headline': 'Profil',
    'user.profile': 'Benutzerprofil',
    'actions.edit': 'bearbeiten',
    'user.actions.change-password': 'Passwort ändern',
    'user.actions.editOrganization': 'Organisation bearbeiten',
    'user.password-reset.page-headline': 'Passwort zurücksetzen',
    'user.password-reset.intro': 'Bitte geben Sie Ihre E-Mail Adresse ein. Wir senden Ihnen ein neues Passwort.',
    'user.password-reset.actions.back-to-login': 'Zurück zur Anmeldung',
    'general.email': 'E-Mail',
    'general.password': 'Passwort',
    'bucket.owner': 'Besitzer',
    'filter.headline': 'Filter',
    'tags.placeholder': 'Tags auswählen',
    'tags.label': 'Tags',
    'upload.uploadHelp': 'Hier klicken um Bild auszuwählen oder Bild per Drag &amp; Drop hier fallen lassen.',
    'upload.replaceDropzone': '<strong>Bild ersetzen</strong><br>Zum Öffnen des Dateiauswahldialogs klicken oder Drag &amp; Drop nutzen.',
    'general.none': 'keine',
    'actions.display': 'anzeigen',
    'actions.delete': 'löschen',
    'actions.label': 'Aktionen',
    'general.of': 'von',
    'actions.confirm.delete': 'Wirklich löschen?',
    'bucket.actions.delete': 'Soll das Ordner wirklich gelöscht werden? Alle enthaltenen Bilder werden ebenfalls unwiederbringlich gelöscht.',
    'bucket.action.deleteUser': 'Soll der Benutzer #{bucket_user.user.company} wirklich gelöscht werden?',
    'actions.confirm.changesAreLost': 'Wollen Sie wirklich schließen? Nicht gesicherte Änderungen gehen verloren.',
    'password-reset.email-does-not-exist': 'Diese E-Mail Adresse ist bei uns nicht im System hinterlegt',
    'password-reset.success-check-emails': 'Bitte prüfen Sie Ihren Posteingang. Sie sollten Ihr neues Passwort erhalten haben. Bitte überprüfen Sie im Zweifelsfall Ihren Spam-Ordner.',
    'users.notifications.create.success': 'Der Benutzer wurde erfolgreich erstellt.',
    'user.profile.notifications.success': 'Ihr Profil wurde aktualisiert.',
    'user.profile.organization.notifications.success': 'Die Organisationsdaten wurde aktualisiert.',
    'bucket.notifications.rename': 'Ordner „{{bucketName}}” erfolgreich gespeichert.',
    'bucket.notifications.delete': 'Ordner „{{bucketName}}” erfolgreich gelöscht.',
    'bucket.notifications.user-does-not-exist': 'Dieser Benutzer existiert nicht.',
    'bucket.notifications.user-added': 'Benutzer hinzugefügt',
    'user.notifications.registered-successfully': 'Die Registrierung war erfolgreich, Sie können sich nun anmelden!',
    'filter.expired.all': 'Alle',
    'filter.expired.valid': 'Gültig',
    'filter.expired.invalid': 'Abgelaufen',
    'upload.multiupload.help': '<strong>Hochladen</strong><br>Mit einem Klick den Dateiauswahldialog öffnen oder einfach Drag & Drop nutzen.',
    'upload.multiupload.files-in-progress': 'Dateien werden hochgeladen...',
    'upload.actions.move': 'Verschieben',
    'upload.confirmations.move': 'Soll das Bild wirklich verschoben werden?',
    'user.roles.page-headline': 'Rollen verwalten',
    'user.roles.uploader.short': 'Hochladen',
    'user.roles.uploader.long': 'Kann einzelne oder mehrere Dateien hochladen',
    'user.roles.viewer.short': 'Anzeigen',
    'user.roles.viewer.long': 'Kann Bilder anzeigen',
    'user.roles.editor.short': 'Bearbeiten',
    'user.roles.editor.long': 'Kann Bilder bearbeiten',
    'user.roles.sharer.short': 'Teilen',
    'user.roles.sharer.long': 'Kann Bilder teilen',
    'user.roles.admin.short': 'Administrator',
    'user.roles.admin.long': 'Kann administrative Tätigkeiten durchführen',
    'user.roles.downloader.short': 'Herunterladen',
    'user.roles.downloader.long': 'Kann Bilder im Original herunterladen',
    'user.roles.creator.short': 'Ersteller',
    'user.roles.creator.long': 'Kann neue Datenbanken erstellen',
    'user.roles.deleter.short': 'Löschen',
    'user.roles.deleter.long': 'Kann Datenbanken und Bilder löschen',
    'user.roles.basicUser.short': 'Basis-User',
    'user.roles.basicUser.long': 'Kann Datenbanken anzeigen und Bilder herunterladen',
    'user.roles.powerUser.short': 'Power-User',
    'user.roles.powerUser.long': 'Kann zusätzlich zum Basis-User neue Datenbanken anlegen, Bilder hochladen und bearbeiten.',
    'user.roles.global-roles-legend': 'Globale Nutzer-Rollen',
    'user.roles.storeUser.short': 'Filiale',
    'user.rights': 'Rechte',
    'user.permissions': 'Berechtigungen',
    'user.permissions.canManageUsers.short': 'User:Admin',
    'user.permissions.canShowBucket.short': 'Bucket:Show',
    'user.permissions.canEditBucket.short': 'Bucket:Edit',
    'user.permissions.canShowUpload.short': 'Upload:Show',
    'user.permissions.canEditUpload.short': 'Upload:Edit',
    'user.permissions.canMoveUpload.short': 'Upload:Move',
    'user.permissions.canCreateBucket.short': 'Bucket:Create',
    'user.permissions.canUploadSingle.short': 'Upload:Upload',
    'user.permissions.canUploadMulti.short': 'Upload:Multiupload',
    'user.permissions.canDownload.short': 'Upload:Download',
    'user.permissions.canDeleteBucket.short': 'Bucket:Delete',
    'user.permissions.canDeleteUpload.short': 'Upload:Delete',
    'user.permissions.canManageImagesizes.short': 'Images:Manage',
    'user.permissions.canShareBucket.short': 'Upload:Share',
    'user.permissions.canPublishBucket.short': 'Upload:Publish',
    'user.permissions.isStore.short': 'Filiale',
    'user.roles.bucket-roles': 'Datenbank-Berechtigungen',
    'tables.footer.entries': '{{count}} Einträge',
    'roles.actions.remove': 'Rolle entfernen',
    'user.your-profile': 'Dein Profil',
    'administration.user-management': 'Benutzerverwaltung',
    'administration.image-sizes': 'Bildgrößen',
    'home': 'Startseite',
    'name': 'Bezeichnung',
    'imagesizes.create.page-headline': 'Bildgröße erstellen',
    'imagesizes.original': 'Originaldatei',
    'upload.download.too-small': 'Die Bildgröße ist kleiner als die Download-Größe, es wird beim Herunterladen hochskaliert.',
    'actions.download': 'Herunterladen',
    'buckets.search.page-headline': 'Volltextsuche',
    'actions.share': 'Teilen',
    'actions.share-with-publisher': 'An Verlag senden',
    'actions.publish': 'Veröffentlichen',
    'actions.unpublish': 'Veröffentl. zurücknehmen',
    'actions.getUrl': 'URL abrufen',
    'actions.delete.tooltip': '<strong>Löschen</strong><br>Kann nicht rückgängig gemacht werden.',
    'actions.download.tooltip': '<strong>Download</strong><br>Bild in verschiedenen Größen herunterladen.',
    'actions.publish.tooltip': '<strong>Veröffentlichen</strong><br>Bild öffentlich machen. Anschließend kann die URL abgerufen werden.',
    'actions.unpublish.tooltip': '<strong>Veröffentl. zurückziehen</strong><br>Das Bild wird nicht mehr öffentlich erreichbar sein.',
    'actions.getUrl.tooltip': '<strong>URL</strong><br>Öffentliche URL für verschiedene Größen abrufen.',
    'actions.filter.tooltip': '<strong>Filter</strong><br>Anhand verschiedener Kriterien die Bildauswahl einschränken.',
    'actions.group.tooltip': '<strong>Gruppieren</strong><br>Anhand einer gemeinsamen Eigenschaft Bilder in der Ansicht zusammenfassen.',
    'actions.upload.tooltip': '<strong>Hochladen</strong><br>Öffnet den Dialog für den Einzelupload.',
    'actions.more.tooltip': '<strong>Weitere Aktionen</strong>',
    'actions.share.tooltip': '<strong>Teilen</strong><br>Bild(er) per E-Mail teilen.',
    'actions.openBucketSettings.tooltip': '<strong>Databankeinstellungen</strong><br>Öffnet die Einstellungen.',
    'actions.home.tooltip': '<strong>Startseite</strong>',
    'actions.search.tooltip': '<strong>Suche</strong>',
    'actions.settings.tooltip': '<strong>Allgemeine Einstellungen</strong>',
    'actions.profile.tooltip': '<strong>Verwalten Sie Ihr Profil.</strong>',
    'actions.manageUsers.tooltip': '<strong>Benutzer, Rechte und Rollen verwalten</strong>',
    'actions.manageImagesizes.tooltip': '<strong>Bildgrößen verwalten.</strong>',
    'actions.logout.tooltip': '<strong>Abmelden</strong>',
    'upload.published.tooltip': '<strong>Veröffentlicht</strong>',
    'actions.createBucket.tooltip': '<strong>Eine neue Bilddatenbank erstellen</strong>',
    'actions.editProfile.tooltip': '<strong>Profil bearbeiten</strong>',
    'actions.changePassword.tooltip': '<strong>Passwort ändern</strong>',
    'actions.createUsers.tooltip': '<strong>Einen neuen Benutzer erstellen</strong>',
    'actions.deselectAll.tooltip': '<strong>Keine markieren</strong>',
    'actions.selectAll.tooltip': '<strong>Alle markieren</strong>',
    'administration.users.roles.help': 'Alle/Keine markieren mit dem Button links. Mit <code>Ctrl</code> + <code>click</code> auf Windows oder <code>⌘</code> + <code>click</code> auf Mac mehrere Einträge markieren.',
    'actions.addImagesize.tooltip': 'Eine neue Bildgröße hinzufügen',
    'suh.division.label': 'Bereich',
    'suh.divisions.press': 'Presse',
    'suh.divisions.book': 'Buch',
    'suh.publisher.label': {
      one: 'Verlag',
      other: 'Verlage'
    },
    'suh.nationalDistributor.label': 'ND',
    'suh.author.label': 'Autor',
    'suh.title.label': 'Titel',
    'suh.issueNumber.label': 'Heftnummer',
    'suh.pos.label': 'Werbefläche',
    'suh.top.label': 'Ist Top-Buchung?',
    'suh.notificationReceiver.label': 'E-Mail-Empfänger',
    'suh.actions.publisher.tooltip': 'Verlage verwalten',
    'suh.actions.issueNumber.tooltip': 'Heftnummern verwalten',
    'suh.actions.title.tooltip': 'Titel verwalten',
    'suh.actions.author.tooltip': 'Autoren verwalten',
    'suh.actions.pos.tooltip': 'PoS verwalten',
    'suh.actions.nationalDistributor.tooltip': 'NDs verwalten',
    'suh.divisions.0': 'Buch',
    'suh.divisions.1': 'Presse',
    'change-to': 'Ändern in',
    'suh.area.label': 'Bereiche',
    'suh.actions.area.tooltip': 'Bereich verwalten',
    'suh.category.label': 'Kategorien',
    'suh.actions.category.tooltip': 'Kategorie verwalten',
    'suh.commodity-group.label': 'Warengruppe',
    'suh.actions.commodity-group.tooltip': 'Warengruppe verwalten',
    'suh.date': 'Datum',
    maintenanceView: {
      imagesizes: {
        index: {
          headline: 'Bildgrößen verwalten',
          'create.tooltip': 'Eine neue Bildgröße hinzufügen'
        },
        'create.headline': 'Bildgrößen erstellen',
        'edit.headline': 'Bildgrößen bearbeiten'
      },
      publishers: {
        index: {
          headline: 'Verlage verwalten',
          'create.tooltip': 'Einen neuen Verlag hinzufügen'
        },
        'create.headline': 'Verlag erstellen',
        'edit.headline': 'Verlag bearbeiten'
      },
      'issue-numbers': {
        index: {
          headline: 'Heftnummern verwalten',
          'create.tooltip': 'Eine neue Heftnummer hinzufügen'
        },
        'create.headline': 'Heftnummer erstellen',
        'edit.headline': 'Heftnummer bearbeiten'
      },
      titles: {
        index: {
          headline: 'Titel verwalten',
          'create.tooltip': 'Einen neuen Titel hinzufügen'
        },
        'create.headline': 'Titel erstellen',
        'edit.headline': 'Titel bearbeiten'
      },
      authors: {
        index: {
          headline: 'Autoren verwalten',
          'create.tooltip': 'Einen neuen Autor hinzufügen'
        },
        'create.headline': 'Autor erstellen',
        'edit.headline': 'Autor bearbeiten'
      },
      pos: {
        index: {
          headline: 'PoS verwalten',
          'create.tooltip': 'Einen neues PoS hinzufügen'
        },
        'create.headline': 'PoS erstellen',
        'edit.headline': 'PoS bearbeiten'
      },
      'national-distributors': {
        index: {
          headline: 'NDs verwalten',
          'create.tooltip': 'Einen neues ND hinzufügen'
        },
        'create.headline': 'ND erstellen',
        'edit.headline': 'ND bearbeiten'
      },
      'upload-common-areas': {
        index: {
          headline: 'Bereiche verwalten',
          'create.tooltip': 'Einen neues Bereich hinzufügen'
        },
        'create.headline': 'Bereich erstellen',
        'edit.headline': 'Bereich bearbeiten'
      },
      'upload-common-categories': {
        index: {
          headline: 'Kategorien verwalten',
          'create.tooltip': 'Eine neue Kategorie hinzufügen'
        },
        'create.headline': 'Kategorie erstellen',
        'edit.headline': 'Kategorie bearbeiten'
      },
      'upload-common-commodity-groups': {
        index: {
          headline: 'Warengruppen verwalten',
          'create.tooltip': 'Eine neue Warengruppe hinzufügen'
        },
        'create.headline': 'Warengruppe erstellen',
        'edit.headline': 'Warengruppe bearbeiten'
      }
    }
  };

  exports['default'] = translations;
});