define('frontend2/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    gender: _emberData['default'].attr('number', {
      defaultValue: 0
    }),
    first_name: _emberData['default'].attr('string'),
    last_name: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    publisher: _emberData['default'].belongsTo('publisher')
  });
});