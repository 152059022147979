define('frontend2/instance-initializers/raven-setup', ['exports', 'ember', 'frontend2/config/environment'], function (exports, _ember, _frontend2ConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(application) {
    if (_ember['default'].get(_frontend2ConfigEnvironment['default'], 'sentry.development') === true) {
      if (_ember['default'].get(_frontend2ConfigEnvironment['default'], 'sentry.debug') === true) {
        _ember['default'].Logger.info('`sentry` is configured for development mode.');
      }
      return;
    }

    if (!_frontend2ConfigEnvironment['default'].sentry) {
      throw new Error('`sentry` should be configured when not in development mode.');
    }

    var _config$sentry$serviceName = _frontend2ConfigEnvironment['default'].sentry.serviceName;
    var serviceName = _config$sentry$serviceName === undefined ? 'raven' : _config$sentry$serviceName;

    var lookupName = 'service:' + serviceName;
    var service = application.lookup ? application.lookup(lookupName) : application.container.lookup(lookupName);

    service.setup(_frontend2ConfigEnvironment['default']);
  }

  exports['default'] = {
    initialize: initialize,
    name: 'sentry-setup'
  };
});