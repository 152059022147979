define('frontend2/routes/administration/users/index/create', ['exports'], function (exports) {
  var AdministrationUsersIndexCreateRoute;

  AdministrationUsersIndexCreateRoute = Ember.Route.extend({
    overlay: Ember.inject.service(),
    user: Ember.inject.service(),
    model: function model() {
      return new Ember.RSVP.hash({
        user: this.get('store').createRecord('user'),
        roles: this.get('store').findAll('role')
      });
    },
    setupController: function setupController(controller, model) {
      model.user.set('organization', this.get('user.model.organization'));
      return controller.set('model', model.user);
    },
    activate: function activate() {
      var _this = this;

      return this.get('overlay').show(function () {
        return _this.transitionTo('administration.users.index');
      });
    },
    deactivate: function deactivate() {
      var user;
      user = this.modelFor('administration.users.index.create').user;
      if (user.get('isNew')) {
        user.deleteRecord();
      }
      return this.get('overlay').hide();
    }
  });

  exports['default'] = AdministrationUsersIndexCreateRoute;
});