define('frontend2/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  var Customer;

  Customer = _emberData['default'].Model.extend({
    stripe_customer_id: _emberData['default'].attr('string'),
    stripe_subscription_id: _emberData['default'].attr('string'),
    user: _emberData['default'].belongsTo('user')
  });

  exports['default'] = Customer;
});