define('frontend2/controllers/buckets/show', ['exports'], function (exports) {
  var BucketsShowController;

  BucketsShowController = Ember.Controller.extend({
    user: Ember.inject.service(),
    upload: Ember.inject.service(),
    tags: null,
    filterTags: [],
    filterName: '',
    filterSource: '',
    filterLicense: '',
    filterValid: null,
    filterNotYetShared: false,
    filterIsTop: 0,
    filterAssociation: 0,
    showFilter: false,
    showGroup: false,
    esSearchResults: null,
    filterUploadType: -1,
    q: '',
    queryParams: ['q'],
    groupBy: '',
    numberOfActiveFilters: Ember.computed('filterTags.[]', 'filterName', 'filterSource', 'filterLicense', 'filterValid', 'filterNotYetShared', 'filterIsTop', function () {
      var field, i, len, num, ref;
      num = 0;
      ref = ['filterTags', 'filterName', 'filterSource', 'filterLicense', 'filterValid'];
      for (i = 0, len = ref.length; i < len; i++) {
        field = ref[i];
        if (Ember.isPresent(this.get(field))) {
          num += 1;
        }
      }
      if (this.get('filterNotYetShared')) {
        num += 1;
      }
      if (this.get('filterIsTop') > 0) {
        num += 1;
      }
      return num;
    }),
    init: function init() {
      var _this = this;

      this.get('store').findAll('tag').then(function (tags) {
        return _this.set('tags', tags);
      });
      return this._super();
    },
    filterOrSearchActive: Ember.computed('filteredUploads', function () {
      return this.get('numberOfActiveFilters') > 0 || this.get('q').length > 0;
    }),
    elasticSearchActive: Ember.observer('q', function () {
      return this.get('q').length > 0;
    }),
    filteredUploads: Ember.computed('model.uploads.[]', 'model.uploads.@each.published', 'model.uploads.@each.shared', 'model.uploads.@each.is_top', 'model.uploads.@each.url', 'filterTags.[]', 'filterName', 'filterSource', 'filterValid', 'filterLicense', 'filterNotYetShared', 'filterIsTop', 'filterAssociation', 'filterUploadType', 'esSearchResults', function () {
      var _this2 = this;

      var promises, uploads;
      // Get uploads found by Elasticsearch query and apply filter afterwards
      if (this.get('esSearchResults') !== null && this.get('q') !== "") {
        if (this.get('esSearchResults').length > 0) {
          // get upload records by id (given by elasticsearch result)
          promises = [];
          this.get('esSearchResults').forEach(function (upload) {
            return promises.pushObject(_this2.get('store').findRecord('upload', upload._id));
          });
          return DS.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              return Ember.RSVP.allSettled(promises).then(function (responses) {
                var results;
                results = [];
                responses.forEach(function (prom) {
                  if (prom.state === 'fulfilled') {
                    return results.push(prom.value);
                  }
                });
                // Apply filter on search results
                return resolve(_this2._filterUploads(results));
              });
            })
          });
        } else {
          return [];
        }
      } else {
        // Apply only filter on uploads
        uploads = this.get('model.uploads');
        return this._filterUploads(uploads || []);
      }
    }),
    cpGroupedUploads: Ember.computed('filteredUploads.[]', 'groupBy', function () {
      var groupField, groups;
      groupField = this.get('groupBy');
      if (Ember.isEmpty(groupField)) {
        return;
      }
      groups = [];
      this.get('filteredUploads').forEach(function (item) {
        var groupValue;
        groupValue = item.get(groupField) || 'undefined';
        if (!(groupValue in groups)) {
          groups[groupValue] = [];
        }
        return groups[groupValue].push(item);
      });
      return groups;
    }),
    // groups uploads and flattens them afterwards into an array
    // works only for belongsTo relationships, groupBy needs to be something like:
    // - author.id
    // - publisher.id
    // The models need to implement toString (will be displayed as group title)
    // the virtual-each splits into 3 chunks per row
    // the template decices on the chunk content, if it is a group title,
    // empty or an upload
    // the resulting array looks similar to (pseudo)
    // [0] - group title (renders a fullwidth cell)
    // [1] - null (renders nothing)
    // [2] - null (renders nothing)
    // [3] - upload (renders standard upload-view)
    // [4] - empty cell (renders an empty cell)
    // [5] - empty cell (renders an empty cell)
    // [6] - group title
    // So it automatically
    // - groups all uploads by the value of property groupBy
    // - creates a flat array
    // - inserts a group title component into the array
    // - inserts two null values so that nothing is rendered
    // - inserts (n) uploads of the group
    // - depending on the missing uploads in a row inserts empty cells
    groupedUploads: Ember.computed('cpGroupedUploads', function () {
      var _insertCell, flattenedGroups, groupField, groupItems, groupName, groupRelationship, groups, i, item, j, len, ref, rest;
      // determine groups
      groupField = this.get('groupBy');
      groupRelationship = groupField.replace('.id', '');
      groups = this.get('cpGroupedUploads');
      // insert necessary stuff
      flattenedGroups = [];
      // a cell has an component name and unknown data
      // passed in the template later
      _insertCell = function (component, data) {
        var key, objectData, value;
        objectData = {
          component: component
        };
        if (data) {
          for (key in data) {
            value = data[key];
            objectData[key] = value;
          }
        }
        return flattenedGroups.push(Ember.Object.create(objectData));
      };

      // flatten the groups into a single 3-chunkable array
      for (groupName in groups) {
        groupItems = groups[groupName];
        if (!groups.hasOwnProperty(groupName)) {
          // don't iterate over functions and stuff
          continue;
        }
        // add the group title
        _insertCell('ext/upload-view-group-title', {
          groupField: groupField,
          groupFieldValue: groupName,
          firstUpload: groupItems[0],
          uploadCount: groupItems.get('length'),
          property: groupRelationship
        });
        // fill with two null values
        flattenedGroups.push(null);
        flattenedGroups.push(null);
        for (i = 0, len = groupItems.length; i < len; i++) {
          item = groupItems[i];
          // add all uploads
          flattenedGroups.push(item);
        }
        // fill with empty cells depending on the number of uploads in the last row
        rest = groupItems.length % 3;
        if (rest !== 0) {
          for (j = 1, ref = 3 - rest; 1 <= ref ? j <= ref : j >= ref; 1 <= ref ? j++ : j--) {
            _insertCell('ext/upload-view-empty-cell');
          }
        }
      }
      // finally return
      return flattenedGroups;
    }),
    _filterUploads: function _filterUploads(uploadsToFilter) {
      var _this3 = this;

      var filterAssociation, filterIsTop, filterLicense, filterName, filterNotYetShared, filterSource, filterUploadType, filterValid, neededHits, now, uploads;
      filterName = this.get('filterName').toLowerCase();
      filterSource = this.get('filterSource').toLowerCase();
      filterLicense = this.get('filterLicense').toLowerCase();
      filterValid = this.get('filterValid');
      filterNotYetShared = this.get('filterNotYetShared');
      filterIsTop = this.get('filterIsTop');
      filterUploadType = this.get('filterUploadType');
      filterAssociation = this.get('filterAssociation');
      now = moment();
      // uploads = @get('model.uploads').toArray().reverse()
      uploads = uploadsToFilter.toArray().reverse().filterBy('isNew', false).filter(function (upload) {
        var filterBool, uploadExpiryDate;
        filterBool = true;
        filterBool = filterBool && upload.get('name').toLowerCase().indexOf(filterName) >= 0;
        filterBool = filterBool && upload.get('source').toLowerCase().indexOf(filterSource) >= 0;
        filterBool = filterBool && (upload.get('license') || '').toLowerCase().indexOf(filterLicense) >= 0;
        if (filterUploadType >= 0) {
          filterBool = filterBool && upload.get('upload_type') === filterUploadType;
        }
        if (filterIsTop > 0) {
          if (filterIsTop === 1) {
            filterBool = filterBool && upload.get('is_top');
          } else if (filterIsTop === 2) {
            filterBool = filterBool && !upload.get('is_top');
          }
        }
        if (filterAssociation > 0) {
          if (filterAssociation === 1) {
            filterBool = filterBool && upload.get('is_association_booking');
          } else if (filterAssociation === 2) {
            filterBool = filterBool && upload.get('is_association_booking') && !upload.get('is_association_premium_booking');
          } else if (filterAssociation === 3) {
            filterBool = filterBool && upload.get('is_association_premium_booking');
          }
        }
        if (filterNotYetShared) {
          filterBool = filterBool && !upload.get('shared');
        }
        if (filterValid != null) {
          uploadExpiryDate = moment(upload.get('expiry_date'));
          if (filterValid.id === 'valid') {
            filterBool = filterBool && !upload.get('isExpired'); //uploadExpiryDate.isAfter(now)
          } else if (filterValid.id === 'invalid') {
              filterBool = filterBool && upload.get('isExpired'); //now.isAfter(uploadExpiryDate)
            }
        }
        return filterBool;
      });
      neededHits = this.get('filterTags').length;
      return uploads.filter(function (upload) {
        var hits;
        hits = 0;
        _this3.get('filterTags').forEach(function (filterTag) {
          return upload.get('tag_uploads').forEach(function (tagUpload) {
            if (tagUpload.get('tag.name') === filterTag.get('name')) {
              return hits += 1;
            }
          });
        });
        return hits === neededHits && (upload.get('is_temporary') === 0 || upload.get('is_temporary') === null);
      });
    },
    actions: {
      filter: function filter(upload) {
        return true;
      },
      resetFilter: function resetFilter() {
        var field, i, len, ref;
        ref = ['filterName', 'filterSource', 'filterLicense'];
        for (i = 0, len = ref.length; i < len; i++) {
          field = ref[i];
          this.set(field, '');
        }
        this.set('filterValid', null);
        this.set('filterTags', []);
        this.set('filterIsTop', 0);
        return this.set('filterAssociation', 0);
      },
      multipleFilesUploads: function multipleFilesUploads(files) {
        var file, i, len, results1, upload;
        results1 = [];
        for (i = 0, len = files.length; i < len; i++) {
          file = files[i];
          upload = this.get('store').createRecord('upload', {
            name: file.name
          });
          upload.set('getInfosAutomatically', true);
          results1.push(this.send('saveUpload', upload, file));
        }
        return results1;
      },
      esSearchChanged: function esSearchChanged(results, query) {
        this.set('esSearchResults', results);
        return this.set('q', query);
      },
      selectGroup: function selectGroup(groupValue) {
        var groupedUploads, i, j, len, len1, results1, results2, upload, uploads;
        groupedUploads = this.get('cpGroupedUploads');
        uploads = groupedUploads[groupValue];
        if (uploads.findBy('selected', false)) {
          results1 = [];
          for (i = 0, len = uploads.length; i < len; i++) {
            upload = uploads[i];
            results1.push(upload.set('selected', true));
          }
          return results1;
        } else {
          results2 = [];
          for (j = 0, len1 = uploads.length; j < len1; j++) {
            upload = uploads[j];
            results2.push(upload.set('selected', false));
          }
          return results2;
        }
      }
    }
  });

  exports['default'] = BucketsShowController;
});